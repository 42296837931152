<div class="show-cards" *ngIf="showCards">
    <div class="show-cards__container" [ngStyle]="{ 'margin-left.px': showCards.length > 1 ? 16 : 0 }">
        <div class="show-cards__slider">
            <div class="show-cards__card" *ngFor="let showCard of showCards" [ngStyle]="{
                    'width.px': width,
                    'height.px': height,
                    'background-image': 'url(' + showCard.image + ')',
                    }"
                    (click)="openFullscreen(showCard.image)">
            </div>
        </div>
    </div>
</div>

<!-- Полноэкранное изображение -->
<div class="fullscreen-overlay" *ngIf="fullscreenImage" (click)="closeFullscreen()">
    <img [src]="fullscreenImage" class="fullscreen-image">
    <button class="close-button" (click)="closeFullscreen($event)">✖</button>
</div>