<div class="basket-order-container">
  <div class="basket-order" *ngIf="foods.length > 0 || drinks.length > 0">
    <div class="basket-order-content">
      <div class="basket-order-content__price">{{ price }} {{ locale?.currency }}</div>
      <div class="basket-order-content__items">
        <span *ngIf="foodsCount">{{ foodsCount }} {{ foodsCount === 1 ? 'блюдо' : 'блюда' }}</span>
        <span *ngIf="foodsCount && drinksCount"> и </span>
        <span *ngIf="drinksCount">{{ drinksCount }} {{ drinksCount === 1 ? 'напиток' : 'напитка' }}</span>
      </div>
    </div>
  </div>
</div>
