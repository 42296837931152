import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Food } from 'src/interfaces/food.model';
import { Locale } from 'src/interfaces/locale.model';
import { Variant } from 'src/interfaces/variant.model';

@Component({
  selector: 'app-menu-dialog-variants',
  templateUrl: './menu-dialog-variants.component.html',
  styleUrls: ['./menu-dialog-variants.component.css']
})

export class MenuDialogVariantsComponent {
  constructor(
    private dataService: DataService, 
  ) {
    this.choise = this.food?.choise || 0;
    this.locale = this.dataService.locale;
  }

  @Input() food : Food | null = null;
  @Output() variantsChange = new EventEmitter(); // Output property

  choise: number;
  locale: Locale | null = null;

  onChoiseClick(index: number): void {
    if (this.food) {
      console.log('MenuDialogVariants | onChoiseClick() | index:', index);
      this.choise = index;
      this.food.choise = index;
      this.variantsChange.emit(); // Emit updated modificators
    }
  }
}
