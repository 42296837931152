import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],
})

export class LandingpageComponent{
  items = Array.from({ length: 100 }, (_, i) => `Content row ${i + 1}`);
  constructor(
    private route: ActivatedRoute) {}
}
