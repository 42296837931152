
<div class="category" *ngIf="category && category.active">
  <div *ngIf="sortedFoods.length || sortedDrinks.length">
    <!-- category title -->
    <div class="category-title">
      <h1>{{ category.title }}</h1>
    </div>
  </div>

  <div class="product-grid">
    <!-- FOODS -->
    <div class="product-grid-cell" *ngFor="let food of sortedFoods">
      <app-food-card [food]="food" [stoplist]="stoplistFoods.includes(food.id)"></app-food-card>
    </div>

    <!-- DRINKS -->
    <div class="product-grid-cell" *ngFor="let drink of sortedDrinks">
      <app-drink-card [drink]="drink" [stoplist]="stoplistDrinks.includes(drink.id)"></app-drink-card>
    </div>
  </div>
</div>

