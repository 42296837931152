<div class="basket-dialog-container">
  <div class="basket-dialog-header">
    <span class="basket-title">Корзина</span>
    <button mat-icon-button (click)="close(); $event.stopPropagation()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="basket-dialog-content">
    <!-- Ваше содержимое корзины -->
  </div>
</div>
