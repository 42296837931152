<div class="parallax-container" *ngIf="restaurant" id="header">
  <div class="header-image" [ngStyle]="{
      'width.px': '100%',
      'height.px': imageHeight,
    }">
  </div>
  <div class="parallax" *ngIf="restaurant && isParralaxVisible" [ngStyle]="{
    'background-image': 'url(' + restaurant.image + ')',
    'width.px': '100%',
    'height.px': imageHeight,
    'transform': 'translateY(' + parallaxOffset + 'px)',
    'opacity': opacity,
  }"></div>
  <div class="header-logo">
    <img [src]="restaurant.logo" alt="Logo" class="logo" [ngStyle]="{
      'height.px': logoSize,
      'width.px': logoSize,
      'opacity': opacity,
    }" />
  </div>
</div>