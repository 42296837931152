
import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { Food } from 'src/interfaces/food.model';
import { Drink } from 'src/interfaces/drink.model';
import { DataService } from 'src/app/services/data.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuBasketDialogComponent } from '../menu-basket-dialog/menu-basket-dialog.component';

@Component({
  selector: 'app-menu-basket-order',
  templateUrl: './menu-basket-order.component.html',
  styleUrls: ['./menu-basket-order.component.css'],
})
export class MenuBasketOrderComponent implements OnInit, OnDestroy{
  constructor(
    private dataService: DataService,
    private basketService: BasketService,
    private scrollService: ScrollService,
    private dialog: MatDialog
  ) {
    this.locale = this.dataService.locale;
  }

  foods: Food[] = [];
  foodsCount: number = 0;
  drinks: Drink[] = [];
  drinksCount: number = 0;
  price: string = '';
  locale: any = { currency: '' };
  private basketFoodsSubscription: Subscription = new Subscription();
  private basketDrinksSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.basketFoodsSubscription = this.basketService.foods.subscribe((data: Food[]) => {
      this.foods = data;
      this.updateBasketPrice();
      console.log('MenuBasket | ngOnInit() | foods:', this.foods.map(x => x.title).join(', '));
    });

    this.basketDrinksSubscription = this.basketService.drinks.subscribe((data: Drink[]) => {
      this.drinks = data;
      this.updateBasketPrice();
      console.log('MenuBasket | ngOnInit() | drinks:', this.drinks.map(x => x.title).join(', '));
    });
  }

  ngOnDestroy(): void {
      this.basketFoodsSubscription?.unsubscribe();
      this.basketDrinksSubscription?.unsubscribe();
  }

  onBasketClicked(): void {
      // Затемняем строку состояния перед открытием модального окна
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#a6a6ae');
  
      this.scrollService.setScrollEnabled(false);
      const dialogRef = this.dialog.open(MenuBasketDialogComponent, {
        panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
        position: { bottom: '0' },
        width: '100%',
        maxWidth: '100%'
      });
  
      dialogRef.afterClosed().subscribe(() => {
        this.scrollService.setScrollEnabled(true);
        
        // Возвращаем исходный цвет строки состояния
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#f5f5ff');
      });
    }

  private updateBasketPrice(): void {
    let total = 0;
    this.foodsCount = 0;
    this.drinksCount = 0;
    
    // Проход по каждому блюду
    this.foods.forEach(food => {

      // Считаем стоимость блюда с учетом количества
      this.foodsCount += food.count;
      total += food.totalPrice * food.count;
    });

    this.drinks.forEach(drink => {

      // Считаем стоимость напитка с учетом количества
      this.drinksCount += drink.count;
      total += drink.totalPrice * drink.count;
    });

    // Обновляем стоимость корзины, отделяем тысяи
    this.price = total.toLocaleString('ru-RU'); 
  }
}
