import { Injectable } from "@angular/core";
import {
    Firestore,
    doc,
    docData
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Units } from "src/interfaces/enums.model";
import { Locale } from "src/interfaces/locale.model";

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    store: Firestore;

    constructor(store: Firestore) {
        this.store = store;
    }

    public fetch(id: string): Observable<Locale> {
        const docRef = doc(this.store, 'locale', id);
        return docData(docRef).pipe(
            map((data: any) => this.transformLocaleData(data))
        ) as Observable<Locale>;
    }

    private transformLocaleData(data: any): Locale {
        // Преобразуем Map<String, String> в Map<Units, string>
        const unitsMap = new Map<Units, string>();
        
        if (data.units && typeof data.units === 'object') {
            for (const [key, value] of Object.entries(data.units)) {
                 // Преобразуем строку в значение Enum
                const unitKey = Units[key as keyof typeof Units];
                if (unitKey !== undefined && typeof value === 'string') {
                    unitsMap.set(unitKey, value);
                }
            }
        }

        return {
            code: data.code,
            currency: data.currency,
            time: data.time,
            units: unitsMap
        };
    }
}