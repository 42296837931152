import { Component, Input } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { DataService } from 'src/app/services/data.service';
import { Drink } from 'src/interfaces/drink.model';
import { Locale } from 'src/interfaces/locale.model';
import { MatDialog } from '@angular/material/dialog';
import { MenuDrinkDialogComponent } from '../menu-drink-dialog/menu-drink-dialog.component';
import { BasketService } from 'src/app/services/basket.service';
import { Modificators } from 'src/interfaces/modificators.model';

@Component({
  selector: 'app-basket-drink-card',
  templateUrl: './menu-basket-drink-card.component.html',
  styleUrls: ['./menu-basket-drink-card.component.css']
})
export class MenuBasketDrinkCardComponent {

  constructor(
    private dataService: DataService,
    private basketService: BasketService,
    private dialog: MatDialog
  ) {
    this.locale = this.dataService.locale;
  }

  locale: Locale | null = null;

  @Input() drink: Drink | null = null;
  @Input() isLast: boolean = false;

  price: string = '';
  // Размер
  size: number | null = null;
  modificators: Modificators | undefined = undefined;

  ngOnInit(): void {
    if (this.drink) {
      // Цена напитка
      if (this.drink.variability && this.drink.variants.length > this.drink.choise) {
        console.log('MenuBasketDrinkCard | ngOnInit() | choise:', this.drink.choise, ' | count:', this.drink.count, ' | price:', this.drink.totalPrice);
        this.price = (this.drink.variants[this.drink.choise].price ?? this.drink.price).toLocaleString('ru-RU');
      } else {
        this.price = this.drink.price.toLocaleString('ru-RU');
      }
      
      // Размер напитка
      if (this.drink.variability && this.drink.variants.length) {
        this.size = (this.drink.variants[this.drink.choise].size ?? this.drink.size);
      } else {
        this.size = this.drink.size;
      }

      // this.modificators = (this.drink.modificators as (Modificators | string)[]).filter((item): item is Modificators => typeof item === 'object');
      // this.modificators = this.modificators.filter((item) => item.items.length > 0);
      // this.modificators = this.modificators.filter((item) => item.items.some((modificator) => modificator.count > 0));

      this.modificators = typeof this.drink.modificators === 'object' ? this.drink.modificators : undefined;
      this.modificators = this.modificators?.items?.length ? this.modificators : undefined;
      this.modificators = this.modificators?.items.some((modificator) => modificator.count > 0) ? this.modificators : undefined;
    }
  }

  increment(): void {
    if (!this.drink) return;
    this.drink.count++;
    this.basketService.updateDrinkInBasket(this.drink);
  }

  decrement(): void {
    if (!this.drink) return;
    if (this.drink.count > 1) {
      this.drink.count--;
      this.basketService.updateDrinkInBasket(this.drink);
    } else {
      this.basketService.removeDrinkFromBasket(this.drink);
    }
  }

  onDrinkClicked(): void {
    const dialogRef = this.dialog.open(MenuDrinkDialogComponent, {
      panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
      position: { bottom: '0' },
      width: '100%',
      maxWidth: '100%',
      data: cloneDeep(this.drink),
    });
  }

  get localSize(): string {
    return this.size ? this.size.toLocaleString('ru-RU', { useGrouping: true }) : '';
  }
}
