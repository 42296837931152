import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Food } from 'src/interfaces/food.model';
import { Locale } from 'src/interfaces/locale.model';

@Component({
  selector: 'app-menu-recomendation-food-variants',
  templateUrl: './menu-recomendation-food-variants.component.html',
  styleUrls: ['./menu-recomendation-food-variants.component.css']
})

export class MenuRecomendationFoodVariantsComponent {
  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
  ) {
    this.choise = this.food?.choise || 0;
    this.locale = this.dataService.locale;
  }

  @Input() food : Food | null = null;
  @Output() variantsRecomendationChange = new EventEmitter(); // Output property

  choise: number;
  locale: Locale | null = null;

  onRecomendationChoiseClick(): void {
    if (this.food) {
      console.log('MenuRecomendationFoodVariants | onRecomendationChoiseClick() | index:', this.choise);
      this.food.choise = this.choise;
      this.variantsRecomendationChange.emit(); // Emit updated modificators
    }
  }

  ngAfterViewInit() {
    if (this.food) this.choise = this.food.choise;
    this.cdr.detectChanges();
  }
}
