// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCmvYfDzRamfM4KZJa7qoqWUDRq6uIkmOE',
    appId: '1:141947618852:web:e27a18597eccb98d3dbab1',
    messagingSenderId: '141947618852',
    projectId: 'edim-app',
    authDomain: 'edim-app.firebaseapp.com',
    storageBucket: 'edim-app.appspot.com',
    measurementId: 'G-S4Z4D82ZEB'
  }
};