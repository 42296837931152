import { Injectable } from '@angular/core';
import { Restaurant } from 'src/interfaces/restaurant.model';
import { Locale } from 'src/interfaces/locale.model';

@Injectable({
    providedIn: 'root'
  })
export class DataService {
    restaurant: Restaurant | null = null;
    locale: Locale | null = null;
}
