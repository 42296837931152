
<div *ngIf="foods.length || drinks.length">
  <div id="inner-grid">
    <h1 id="premiere-title">Новинки</h1>
  </div>
  <div class="contained" id="inner-grid">
    <!-- FOODS -->
    <div class="grid-cell" *ngFor="let food of foods">
      <app-food-card [food]="food"></app-food-card>
    </div>
    <!-- DRINKS -->
    <div class="grid-cell" *ngFor="let drink of drinks">
      <app-drink-card [drink]="drink"></app-drink-card>
    </div>
  </div>
</div>
