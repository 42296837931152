import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, Input , ChangeDetectorRef} from '@angular/core';
import { Restaurant } from '../../../interfaces/restaurant.model';
import { DataService } from 'src/app/services/data.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.css']
})
export class MenuHeaderComponent implements AfterViewInit{
  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef, 
    private scrollService: ScrollService
  ) {
    this.transformStyle = 'scale(1.5)';
    this.scrollSubscription = new Subscription();
  }

  @ViewChild('restaurantContainer', { static: true })
  restaurantContainer!: ElementRef;
  @Input() restaurant: Restaurant | null = null;

  storeTitle = 'Starbucks BEO';

  // Opacity
  opacity = 1;
  
  // Logo styles
  initLogoSize = 0;
  logoSize: number | null = null;
  
  // Image styles
  initImageHeight = 0;
  imageHeight: number | null = null;

  // Setting styles
  offsetY = 0.20;
  remainderHight = 0;
  transformStyle: string;

  // Parallax
  parallaxOffset: number = 0;
  parallaxHight = 0;
  isParralaxVisible = false;
  isScrollEnabled = true;
  private scrollSubscription: Subscription;

  searchPlaceholder = 'Поиск';

  ngOnInit(): void {
    this.isParralaxVisible = true;
    this.scrollSubscription = this.scrollService.scrollEnabled$.subscribe(
      enabled => this.isScrollEnabled = enabled
    );
  }

  ngAfterViewInit() {
    // Получаем высоту экрана
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    this.setImageStyles(vh);
    // Получаем ширину блока с картинкой
    const width = document.querySelector('#header')!.clientWidth;
    this.setLogoStyles(width);
    // console.log('MenuHeaderComponent | ngAfterViewInit() | vh:', vh, 'width:', width);
    this.cdr.detectChanges(); 
    // console.log('RestaurantLogoComponent | ngAfterViewInit() | initialHeight:', this.initialHeight);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    // console.log('MenuHeaderComponent | onResize() | vh:', vh);
    this.setImageStyles(vh);
  }

  private setImageStyles(height: number): void {
    this.initImageHeight = height / 4.5;
    this.imageHeight = this.initImageHeight;
    this.parallaxHight = this.initImageHeight;
    this.remainderHight = this.initImageHeight - this.initImageHeight * this.offsetY;
  }
  // Logo styles
  private setLogoStyles(width: number): void {
    this.initLogoSize = width / 4;
    this.logoSize = this.initLogoSize;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.isScrollEnabled) {
      return;
    }

    // Прокрутка страницы
    const currentScroll = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (currentScroll > this.initImageHeight) {
      this.isParralaxVisible = false;
      return;
    } else {
      this.isParralaxVisible = true;
    }
    // console.log('MenuHeaderComponent | onWindowScroll() | scrollY:', currentScroll);
    
    // Процент прокрутки относительно высоты изображения
    const scrollPercentage = currentScroll / this.initImageHeight;
  
    // Текущий размер логотипа
    this.logoSize = this.initLogoSize * (1 - scrollPercentage);

    // Высота изображения к которой не применяется прозрачность
    let viewArea = this.initImageHeight - this.remainderHight;

    this.parallaxHight = this.initImageHeight - ( window.scrollY /2);
    // console.log('MenuHeaderComponent | onWindowScroll() | imageHeight:', this.imageHeight);

    this.parallaxOffset = - scrollY * 0.4; // Регулируйте коэффициент для эффекта параллакса

    // Прозрачность
    if (scrollPercentage > this.offsetY) {
      // Процент прозрачности
      const opacityPercentage = (currentScroll - viewArea) / this.remainderHight;
      this.opacity = Math.max((1 - opacityPercentage * 2), 0);
      // console.log('MenuHeaderComponent | onWindowScroll() | opacity:', this.opacity);
    } else {
      this.opacity = 1;
      const transformPercentage = currentScroll / viewArea;
      this.transformStyle = `scale(1.${1-transformPercentage})`;
      // console.log('MenuHeaderComponent | onWindowScroll() | transform:', this.transformStyle);
    }

    this.isParralaxVisible  = (this.opacity > 0);

  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }
}
