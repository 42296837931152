<div *ngIf="dataService.restaurant && dataService.locale; else loading">
  <!-- Ограничиваем ширину контейнера -->
  <div class="container">
    <!-- Логотип и строка поиска -->
    <app-menu-header [restaurant]="restaurant"></app-menu-header>

    <!-- Заголовок ресторана -->
    <app-restaurant-title></app-restaurant-title>

    <!-- Слайдер с событиями -->
    <!-- <app-events-slider></app-events-slider> -->

    <!-- Навигация -->
    <app-menu-navigation></app-menu-navigation>

    <!-- Блок Новинки -->
    <!-- <app-category-premiere></app-category-premiere> -->

    <!-- Категории -->
    <app-menu-categories [categories]="dataService.restaurant.categories"></app-menu-categories>
  </div>
  
  <!-- Корзина -->
  <app-menu-basket></app-menu-basket>
</div>

<ng-template #loading>
  <div class="loading-indicator">
    <!-- Можно добавить кастомный индикатор загрузки здесь -->
    <p>Загрузка...</p>
  </div>
</ng-template>
