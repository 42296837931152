import { Injectable } from "@angular/core";
import {
    Firestore,
    doc,
    docData
} from "@angular/fire/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StoplistService {
    private foodsSubject : BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public foods$: Observable<string[]> = this.foodsSubject.asObservable();
    private drinksSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public drinks$ : Observable<string[]> = this.drinksSubject.asObservable();

    constructor(private store: Firestore) {}

    public fetch(id: string): void {
        const docRef = doc(this.store, 'stoplist', id);
        // console.log('StopListService | fetchStopListData() | id:', id);
        docData(docRef).pipe(
            map(data => {
                if (data) {
                    const stopListData = data as { foods?: string[], drinks?: string[] };
                    return {
                        foods: stopListData.foods || [],
                        drinks: stopListData.drinks || []
                    };
                } else {
                    return { foods: [], drinks: [] }; // Default value if data is undefined
                }
            })
        ).subscribe(stopListData => {
            this.foodsSubject.next(stopListData.foods);
            this.drinksSubject.next(stopListData.drinks);
        });
    }

    // Getters (only for begin values)
    public foods(category: string | undefined): string[] {
        console.log('StoplistService | foods() | category:', category);
        return this.foodsSubject.getValue();
    }

    // Getters (only for begin values)
    public drinks(category: string | undefined): string[] {
        console.log('StoplistService | drinks() | category:', category);
      return this.drinksSubject.getValue();
    }
}
