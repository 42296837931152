<div class="drink-dialog-container">

  <div class="drink-dialog-header"></div>

  <div mat-icon-button (click)="close(); $event.stopPropagation()" class="close-button">
    <mat-icon class="close-icon">close</mat-icon>
  </div>

  <div class="drink-dialog-title__header">
    <p>{{ data.title.trim() }}</p>
  </div>

  <div class="drink-dialog-content" #scrollContent>

    <!-- Изображение напитка -->
    <div class="image-container">

      <img class="drink-dialog-image" mat-card-image [src]="getImageSrc(data.image)" loading="eager" decoding="async"
        alt="Photo of the drink" />
    </div>

    <!-- Наименование напитка -->
    <div class="drink-dialog-title__container" #titleOriginal>
      <div class="drink-dialog-title">{{ data.title }}</div>
      <img *ngIf="data.kkal || data.fats || data.carbs || data.proteins"
        src="assets/info.svg" 
        alt="info" 
        class="drink-dialog-button" 
        (click)="toggleInfo(); $event.stopPropagation()" 
      />
    </div>
    

    <!-- Вес и время приготовления -->
    <div class="drink-dialog-info">
      <div class="drink-dialog-info__price">{{ price }} {{ locale?.currency }}</div>
      <div class="drink-dialog-info__size" *ngIf="size">{{ localSize }} {{ locale?.units?.get(data.unit) }}</div>
      <div class="drink-dialog-info__kkal" *ngIf="kkal">{{ roundedKkal }} ккал</div>
      <div class="drink-dialog-info__time" *ngIf="data.prepare">{{ data.prepare }} {{ locale?.time }}</div>
    </div>

    <!-- Энергетическая ценность -->
    <div *ngIf="isInfoVisible" 
     [@slideToggle]>
      <div *ngIf="data.kkal || data.fats || data.carbs || data.proteins" class="drink-dialog-info__energy">
        <div class="drink-dialog-info__energy__title">Энергетическая ценность ({{ data.energy ? '100 ' +
          (locale?.units?.get(data.unit) || 'мл') : 'напитка' }})</div>
        <div class="drink-dialog-info__energy__values">
          <div class="drink-dialog-info__energy__value" *ngIf="data.fats">Жиры: {{ data.fats }}</div>
          <div class="drink-dialog-info__energy__value" *ngIf="data.carbs">Углеводы: {{ data.carbs }}</div>
          <div class="drink-dialog-info__energy__value" *ngIf="data.proteins">Белки: {{ data.proteins }}</div>
        </div>
      </div>
    </div>

    <!-- Описание напитка -->
    <div class="drink-dialog-description">{{ data.description }}</div>

    <!-- Варианты напитка -->
    <div *ngIf="data.variability && data.variants.length">
      <app-menu-dialog-drink-variants [drink]="data"
        (variantsChange)="onVariantsChange()"></app-menu-dialog-drink-variants>
    </div>

    <!-- Grid модификаторов -->
    <app-menu-modificators-grid *ngIf="modificators && modificators.items?.length" 
        [modificators]="modificators" (modificatorsChange)="onModificatorsChange()">
    </app-menu-modificators-grid>

    <!-- Рекомендации -->
    <app-menu-recomendations *ngIf="data.recomendations.length"
      [recomendations]="data.recomendations">
    </app-menu-recomendations>

    <div class="drink-dialog-footer"></div>


    <!-- Кнопки для заказа -->
    <div class="drink-dialog-order">

      <!-- Блок управления количеством -->
      <div class="order-quantity">
        <button class="bascket-count-dec" (click)="decrement(); $event.stopPropagation()"
          [ngStyle]="{'color': drink.count > 1 ? '#000000' : '#9e9e9e'}">−</button>
        <div class="order-button__count">{{ drink.count }}</div>
        <button class="bascket-count-inc" (click)="increment(); $event.stopPropagation()">+</button>
      </div>

      <!-- Кнопка добавления в корзину -->
      <button class="basket-button" (click)="buttonPushRemoveDrinkBasket(); $event.stopPropagation()"
        [ngStyle]="{'color': isBasket && !canUpdate ? 'red' : 'black'}">
        <span *ngIf="!isBasket">Добавить</span>
        <span *ngIf="isBasket && !canUpdate">Убрать</span>
        <span *ngIf="isBasket && canUpdate">Обновить</span>
        {{ totalPrice }} {{ locale?.currency }}
      </button>
    </div>
  </div>
</div>