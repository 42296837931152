import { Injectable } from "@angular/core";
import {
    Firestore,
    doc,
    docData
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Restaurant } from "src/interfaces/restaurant.model";


@Injectable({
    providedIn: 'root'
  })

export class RestaurantsService {
    store: Firestore;
    constructor(store: Firestore) {
        this.store = store;
    }

    public getRestaurantData(id: string): Observable<Restaurant> {
        const docRef = doc(this.store, 'stores', id);
        return docData(docRef, { idField: 'id' }) as Observable<Restaurant>

    }

}