import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import { map, Subscription, tap } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ProductService } from 'src/app/services/product.service';
import { StoplistService } from 'src/app/services/stoplist.service';
import { Drink } from 'src/interfaces/drink.model';
import { Food } from 'src/interfaces/food.model';
import { Locale } from 'src/interfaces/locale.model';
import { BasketService } from 'src/app/services/basket.service';
import { MenuFoodRecomendationDialogComponent } from '../menu-food-recomendation-dialog/menu-food-recomendation-dialog.component';
import { MenuDrinkRecomendationDialogComponent } from '../menu-drink-recomendation-dialog/menu-drink-recomendation-dialog.component';

@Component({
  selector: 'app-menu-recomendations',
  templateUrl: './menu-recomendations.component.html',
  styleUrls: ['./menu-recomendations.component.css']
})

export class MenuRecomendationsComponent implements AfterViewInit {
  constructor(
    private dataService: DataService, 
    private productService: ProductService,
    private basketService: BasketService,
    private stoplistService: StoplistService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.locale = this.dataService.locale;

    this.recommendedFoods = [];
    this.recommendedDrinks = [];

    this.basketFoods = [];
    this.basketDrinks = [];

    this.stoplistFoods = [];
    this.stoplistDrinks = [];

    this.subscriptionFoods = new Subscription();
    this.subscriptionDrinks = new Subscription();
    
    this.subscriptionStoplistFoods = new Subscription();
    this.subscriptionStoplistDrinks = new Subscription();

    this.basketFoodsSubscription = new Subscription();
    this.basketDrinksSubscription = new Subscription();

    // Получаем ширину экрана
    this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this.vw = Math.min(this.vw, 480);

    this.width = 220;
    this.height = 100;
  }

  @Input() recomendations: string[] = [];

  locale: Locale | null = null;

  recommendedFoods: Food[];
  recommendedDrinks: Drink[];

  private basketFoods: Food[];
  private basketDrinks: Drink[];

  private stoplistFoods: string[];
  private stoplistDrinks: string[];

  private subscriptionFoods: Subscription;
  private subscriptionDrinks: Subscription;
  private subscriptionStoplistFoods: Subscription;
  private subscriptionStoplistDrinks: Subscription;
  private basketFoodsSubscription: Subscription;
  private basketDrinksSubscription: Subscription;

  title: string = 'Часто заказывают вместе';

  private vw: number;
  width: number;
  height: number;

  ngOnInit(): void {
    console.log('MenuRecomendations | ngOnInit()');
    
    // Subscribe to stoplist foods
    this.subscriptionStoplistFoods = this.stoplistService.foods$.subscribe(stoplistFoods => {
      console.log('MenuRecomendations | ngOnInit() | subscribe() stoplistFoods: ', stoplistFoods.map(x => x).join(', '));
      this.stoplistFoods = stoplistFoods;
    });
    
    // Subscribe to stoplist drinks
    this.subscriptionStoplistDrinks = this.stoplistService.drinks$.subscribe(stoplistDrinks => {
      console.log('MenuRecomendations | ngOnInit() | subscribe() stoplistDrinks: ', stoplistDrinks.map(x => x).join(', '));
      this.stoplistDrinks = stoplistDrinks;
    });

    // foods
    this.subscriptionFoods = this.productService.foods$.pipe(
      map(foods => foods.filter(food => this.recomendations.includes(food.id))),
      tap(filtered => {
        console.log('MenuRecomendations | ngOnInit() | subscribe() | foods: ', filtered.length);
        if (filtered.length) {
          this.recommendedFoods = filtered.filter(food => !this.stoplistFoods.includes(food.id));
        } else {
          this.recommendedFoods = [];
        }
      })
    ).subscribe();

    // drinks
    this.subscriptionDrinks = this.productService.drinks$.pipe(
      map(drinks => drinks.filter(drink => this.recomendations.includes(drink.id))),
      tap(filtered => {
        console.log('MenuRecomendations | ngOnInit() | subscribe() | drinks: ', filtered.length);
        if (filtered.length) {
          this.recommendedDrinks = filtered.filter(drink => !this.stoplistDrinks.includes(drink.id));
        } else {
          this.recommendedDrinks = [];
        }
      })
    ).subscribe();

    this.basketFoodsSubscription = this.basketService.foods.subscribe((data: Food[]) => {
      this.basketFoods = data;
    });

    this.basketDrinksSubscription = this.basketService.drinks.subscribe((data: Drink[]) => {
      this.basketDrinks = data;
    });
  }

  ngAfterViewInit() {
    this.width = (Math.min(this.vw || 480) - 18) / 1.6;
    this.height = this.width * 0.40;
    this.cdr.detectChanges(); // Обновляем Angular-поток изменений
  }

  onFoodClicked(food: Food ): void {
    // Затемняем строку состояния перед открытием модального окна
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#717075');
    this.cdr.detectChanges(); // Обновляем Angular-поток изменений

    const basketFood = this.getBasketFood(food);
    const dialogRef = this.dialog.open(MenuFoodRecomendationDialogComponent, {
      panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
      position: { bottom: '0' },
      width: '100%',
      maxWidth: '100%',
      data:  basketFood ? cloneDeep(basketFood) : cloneDeep(food),
    });

    dialogRef.afterClosed().subscribe(() => {
      // Возвращаем исходный цвет строки состояния
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#a6a6ae');
      this.cdr.detectChanges(); // Обновляем Angular-поток изменений
    });
  }

  onDrinkClicked(drink: Drink ): void {
    // Затемняем строку состояния перед открытием модального окна
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#717075');
    this.cdr.detectChanges(); // Обновляем Angular-поток изменений

    const basketDrink = this.getBasketDrink(drink);
    const dialogRef = this.dialog.open(MenuDrinkRecomendationDialogComponent, {
      panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
      position: { bottom: '0' },
      width: '100%',
      maxWidth: '100%',
      data:  basketDrink ? cloneDeep(basketDrink) : cloneDeep(drink),
    });

    dialogRef.afterClosed().subscribe(() => {
      // Возвращаем исходный цвет строки состояния
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#a6a6ae');
      this.cdr.detectChanges(); // Обновляем Angular-поток изменений
    });
  }

  getBasketFood(food: Food): Food | null {
    return this.basketFoods.find(f => f.id === food.id) || null;
  }

  getBasketDrink(drink: Drink): Drink | null {
    return this.basketDrinks.find(d => d.id === drink.id) || null;
  }

  ngOnDestroy(): void {
    // Отписка при уничтожении компонента
    this.subscriptionFoods?.unsubscribe(); 
    this.subscriptionDrinks?.unsubscribe();
    this.subscriptionStoplistFoods?.unsubscribe();
    this.subscriptionStoplistDrinks?.unsubscribe();
    this.basketFoodsSubscription?.unsubscribe();
    this.basketDrinksSubscription?.unsubscribe();
  }
}
