import { Injectable } from "@angular/core";
import {
    Firestore,
    collection,
    collectionData,
    query,
    where,
    DocumentData
} from "@angular/fire/firestore";
import { BehaviorSubject, Observable, EMPTY, switchMap, distinctUntilChanged } from "rxjs";
import { RestaurantsService } from "./restaurant.service";
import { ShowCard } from "src/interfaces/show-cards.model"; // Путь до интерфейса Event

@Injectable({
    providedIn: 'root'
})
export class ShowCardsService {
    private showCardsSubject = new BehaviorSubject<ShowCard[]>([]);
    public showCards$ = this.showCardsSubject.asObservable();
    private cache = new Map<string, ShowCard[]>(); // Кеш событий

    constructor(
        private store: Firestore,
        private restaurantsService: RestaurantsService
    ) {
        this.restaurantsService.restaurant$.pipe(
            distinctUntilChanged((prev, curr) => prev?.id === curr?.id), // Исключаем дубликаты
            switchMap((restaurant) => this.getOrFetchShowCards(restaurant?.id ?? ''))
        ).subscribe(showCards => this.showCardsSubject.next(showCards));
    }

    private getOrFetchShowCards(storeId: string): Observable<ShowCard[]> {
        if (!storeId) return EMPTY;

        if (this.cache.has(storeId)) {
            return new BehaviorSubject<ShowCard[]>(this.cache.get(storeId)!).asObservable();
        }

        return new Observable<ShowCard[]>((subscriber) => {
            const showCardsCollectionRef = collection(this.store, 'events');
            const filteredEventsQuery = query(showCardsCollectionRef,
                where('active', '==', true),
                where('store', '==', storeId)
            );

            const unsubscribe = collectionData(filteredEventsQuery, { idField: 'id' })
                .subscribe((documents: DocumentData[]) => {
                    // Преобразование данных из Firestore в тип Event[]
                    const showCards: ShowCard[] = documents.map((doc) => ({
                        index: doc["index"],
                        image: doc["image"],
                        thumbImage: doc["thumbImage"],
                        title: doc["title"],
                        description: doc["description"],
                        deadline: doc["deadline"] ? doc["deadline"].toDate() : null,
                        active: doc["active"],
                        store: doc["store"]
                    }));

                    this.cache.set(storeId, showCards); // Обновляем кеш
                    subscriber.next(showCards);
                });

            return () => unsubscribe.unsubscribe();
        });
    }
}