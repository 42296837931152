
import { Component, OnChanges, Input, SimpleChanges} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Category } from '../../../interfaces/category.model';
import { Food } from 'src/interfaces/food.model';
import { Drink } from 'src/interfaces/drink.model';
import { CategoryService } from 'src/app/services/category.service';
import { StoplistService } from 'src/app/services/stoplist.service';
import { ModificatorsService } from 'src/app/services/modificators.service';
import { Modificator, Modificators } from 'src/interfaces/modificators.model';


@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.css'],
}) 
export class MenuCategoryComponent implements OnChanges{
  constructor(
    private productService: ProductService, 
    private categoryService: CategoryService,
    private stoplistService: StoplistService,
    private modificatorsService: ModificatorsService
  ) {
    this.stoplistFoods = [];
    // Для получения начального списка
    this.stoplistDrinks = [];
  }
  @Input() category: Category | null= null;

  sortedFoods: Food[] = [];
  sortedDrinks: Drink[] = [];
  stoplistFoods: string[];
  stoplistDrinks: string[] = [];

  modificators: string[] = [];

  ngOnInit(): void {
    // Subscribe to stoplist foods and drinks
    this.stoplistService.foods$.subscribe(stoplistFoods => {
      // console.log('CategoryCardComponent | ngOnInit() | subscribe() stoplistService: ', stoplistFoods.length);
      this.stoplistFoods = stoplistFoods;
    });
    
    this.stoplistService.drinks$.subscribe(stoplistDrinks => {
      // console.log('CategoryCardComponent | ngOnInit() | subscribe() stoplistDrinks: ', stoplistDrinks.length);
      this.stoplistDrinks = stoplistDrinks;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category'] && changes['category'].currentValue) { 
      
      // Если категория не активаная (не сработал фильтр при загрузке) пропускаем
      if (!this.category?.active) return

      this.stoplistFoods = this.stoplistService.foods(this.category?.title);

      // foods
      this.productService.foods(this.category!.id).subscribe(foods => {
        if (foods) {
          this.sortedFoods = foods.slice().sort((a, b) => a.index - b.index);

          if (foods.length) {
            // console.log('CategoryCardComponent | ngOnChanges() | category: ', this.category!.title);
            this.categoryService.addCategory(this.category!);

            // Replace strings to modificators
            foods.forEach(food => {
              // Предположим, что `food.modificators` это массив с элементами типа `string` и `Modificators`
              let strings = (food.modificators as (Modificator | string)[]).filter((item): item is string => typeof item === 'string');
              food.modificators = [] as Modificators[];
              strings.forEach(id => {
                this.modificatorsService.modificators(id).subscribe(
                      modificators => {
                        if (modificators) {
                          (food.modificators as Modificators[]).push(modificators);
                          // console.log('CategoryCardComponent | ngOnChanges() | modificators: ', modificators.title);
                        }
                      }
                    );
              });
            });
          }

        } else {
          // Если категория пустая - пропускаем
          console.log('CategoryCardComponent | ngOnChanges() | category: ', this.category!.title, ' - empty');
          this.sortedFoods = [];
        }
      });

      // drinks
      this.productService.drinks(this.category!.id).subscribe(drinks => {
        if (drinks) {
          this.sortedDrinks = drinks.slice().sort((a, b) => a.index - b.index);
          if (drinks.length) {
            // console.log('CategoryCardComponent | ngOnChanges() | category: ', this.category!.title);
            this.categoryService.addCategory(this.category!);
          }
        } else {
          this.sortedDrinks = [];
        }
      });

    }
  }
}
