
import { Component, OnChanges, Input, SimpleChanges} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Category } from '../../../interfaces/category.model';
import { Food } from 'src/interfaces/food.model';
import { Drink } from 'src/interfaces/drink.model';
import { StoplistService } from 'src/app/services/stoplist.service';
import { map, Subscription, tap } from 'rxjs';
import { NavigationCategoryService } from 'src/app/services/navigation-category.service';


@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.css'],
}) 
export class MenuCategoryComponent implements OnChanges{
  constructor(
    private productService: ProductService, 
    private navigationCategoryService: NavigationCategoryService,
    private stoplistService: StoplistService,
  ) {
    // Для получения начального списка
    this.stoplistFoods = [];
    this.stoplistDrinks = [];
    this.subscriptionFoods = new Subscription();
    this.subscriptionDrinks = new Subscription();
    this.subscriptionStoplistFoods = new Subscription();
    this.subscriptionStoplistDrinks = new Subscription();
  }
  @Input() category: Category | null= null;

  sortedFoods: Food[] = [];
  sortedDrinks: Drink[] = [];
  stoplistFoods: string[];
  stoplistDrinks: string[] = [];

  private subscriptionFoods: Subscription;
  private subscriptionDrinks: Subscription;
  private subscriptionStoplistFoods: Subscription;
  private subscriptionStoplistDrinks: Subscription;

  ngOnInit(): void {
    // Subscribe to stoplist foods and drinks
    this.subscriptionStoplistFoods = this.stoplistService.foods$.subscribe(stoplistFoods => {
      // console.log('CategoryCardComponent | ngOnInit() | subscribe() stoplistFoods: ', stoplistFoods.length);
      this.stoplistFoods = stoplistFoods;
    });
    
    this.subscriptionStoplistDrinks = this.stoplistService.drinks$.subscribe(stoplistDrinks => {
      // console.log('CategoryCardComponent | ngOnInit() | subscribe() stoplistDrinks: ', stoplistDrinks.length);
      this.stoplistDrinks = stoplistDrinks;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category'] && changes['category'].currentValue) { 
      
      // Если категория не активная (не сработал фильтр при загрузке) пропускаем
      if (!this.category?.active) return

      console.log('MenuCategory | ngOnChanges() | category: ', this.category?.title);

      // foods
      this.subscriptionFoods = this.productService.foods$.pipe(
        map(foods => foods.filter(food => food.category === this.category!.id)), // Фильтруем по категории
        tap(filtered => {
          if (filtered.length) {
            this.sortedFoods = filtered.sort((a, b) => a.index - b.index);
            this.navigationCategoryService.addCategory(this.category!);
            console.log('MenuCategory | ngOnChanges() | subscribe() | foods: ', filtered.map(x => x.title + ' modificators: ' + typeof x.modificators).join(', '));
          } else {
            this.sortedFoods = [];
            if (this.sortedDrinks.length === 0) {
              this.navigationCategoryService.removeCategory(this.category!.id);
            }
          }
        }),
      ).subscribe();
      
      // drinks
      this.subscriptionDrinks = this.productService.drinks$.pipe(
        map(drinks => drinks.filter(drink => drink.category === this.category!.id)), // Фильтруем по категории
        tap(filtered => {
          if (filtered.length) {
            this.sortedDrinks = filtered.slice().sort((a, b) => a.index - b.index);
            this.navigationCategoryService.addCategory(this.category!);
          } else {
            this.sortedDrinks = [];
            if (this.sortedFoods.length === 0) {
              this.navigationCategoryService.removeCategory(this.category!.id);
            }
          }
        }),
      ).subscribe();
    }
  }

  ngOnDestroy(): void {
    // Отписка при уничтожении компонента
    this.subscriptionFoods?.unsubscribe(); 
    this.subscriptionDrinks?.unsubscribe();
    this.subscriptionStoplistFoods?.unsubscribe();
    this.subscriptionStoplistDrinks?.unsubscribe();
  }
}
