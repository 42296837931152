import { Injectable } from "@angular/core";
import {
    Firestore,
    collection,
    collectionData,
    query,
    where,
} from "@angular/fire/firestore";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { Food } from "src/interfaces/food.model";
import { Restaurant } from "src/interfaces/restaurant.model";
import { DataService } from "./data.service";
import { Drink } from "src/interfaces/drink.model";
import { Labels, Units } from 'src/interfaces/enums.model';
import { map, switchMap, tap } from 'rxjs/operators'; 
import { Variant } from "src/interfaces/variant.model";
import { CategoryService } from "./category.service";
import { ModificatorsService } from "./modificators.service";

@Injectable({
    providedIn: 'root'
  })

export class ProductService {
  private foodsSubject: BehaviorSubject<Food[]> = new BehaviorSubject<Food[]>([]);
  public foods$: Observable<Food[]> = this.foodsSubject.asObservable();

  private drinksSubject: BehaviorSubject<Drink[]> = new BehaviorSubject<Drink[]>([]);
  public drinks$: Observable<Drink[]> = this.drinksSubject.asObservable();

  store: Firestore;
  restaurant: Restaurant | null;
  constructor(
    store: Firestore,
    private dataService: DataService,
    private categoryService: CategoryService,
    private modificatorsService: ModificatorsService
  ) {
    this.store = store;
    this.restaurant = this.dataService.restaurant;
    // Подписка на изменение категорий
    this.categoryService.categories$.pipe(
      switchMap(categories => {
          const categoryIds = categories.sort((a, b) => a.index - b.index)
          .map(c => c.id);
          return this.getFoods(categoryIds);
      })
    ).subscribe(foods => this.foodsSubject.next(foods));
    this.categoryService.categories$.pipe(
      switchMap(categories => {
          const categoryIds = categories.sort((a, b) => a.index - b.index)
          .map(c => c.id);
          return this.getDrinks(categoryIds);
      })
    ).subscribe(drinks => this.drinksSubject.next(drinks));
  }

  private getFoods(categoryIds: string[]): Observable<Food[]> {
    if (categoryIds.length === 0) return new BehaviorSubject<Food[]>([]).asObservable();

    const foodsCollectionRef = collection(this.store, "foods");
    let foodQuery = query(foodsCollectionRef, where("category", "in", categoryIds));

    if (this.restaurant?.id) {
      foodQuery = query(foodQuery, where("stores", "array-contains", this.restaurant.id));
    }

    return collectionData(foodQuery, { idField: "id" }).pipe(
      map((foods: any[]) => foods.map(food => this.transformFood(food))),
      switchMap(foods => {
        if (!foods.length) return of(foods);
  
        return combineLatest(
          foods.map(food => {
            const modificatorsID = typeof food.modificators === 'string' ? food.modificators : undefined;
  
            if (!modificatorsID) {
              return of(food); // Если нет модификаторов, просто возвращаем food
            }

            return this.modificatorsService.modificators(modificatorsID).pipe(
              tap(modificators => console.log('ProductService | Food: ', food.title, ' Modificators: ', modificators)),
              map(modificators => {
                return { ...food, modificators }; // Создаем новый объект, обновляя `modificators`
              })
            );
          })
        );
      })
    ) as Observable<Food[]>;
  }

  private getDrinks(categoryIds: string[]): Observable<Drink[]> {
    if (categoryIds.length === 0) return new BehaviorSubject<Drink[]>([]).asObservable();

    const drinksCollectionRef = collection(this.store, "drinks");
    let drinkQuery = query(drinksCollectionRef, where("category", "in", categoryIds));

    if (this.restaurant?.id) {
      drinkQuery = query(drinkQuery, where("stores", "array-contains", this.restaurant.id));
    }

    return collectionData(drinkQuery, { idField: "id" }).pipe(
      map((drinks: any[]) => drinks.map(drink => this.transformDrink(drink))),
      switchMap(drinks => {
        if (!drinks.length) return of(drinks);
  
        return combineLatest(
          drinks.map(drink => {
            const modificatorsID = typeof drink.modificators === 'string' ? drink.modificators : undefined;
  
            if (!modificatorsID) {
              return of(drink); // Если нет модификаторов, просто возвращаем drink
            }

            return this.modificatorsService.modificators(modificatorsID).pipe(
              tap(modificators => console.log('ProductService | Drink: ', drink.title, ' Modificators: ', modificators)),
              map(modificators => {
                return { ...drink, modificators }; // Создаем новый объект, обновляя `modificators`
              })
            );
          })
        );
      })
    ) as Observable<Drink[]>;
  }

  // Внедряем функцию в сервис
  private transformFood(food: any): Food {
    return {
      ...food,
      labels: food.labels ? food.labels.map(getLabelEnumValue) : [],
      unit: getUnitEnumValue(food.unit), // Преобразование unit
      variants: food.variants ? food.variants.map(getVariantValue) : [],
      choise: 0, // Начальный вариант
      count: 0, // Начальное количество для корзины
      totalPrice: food.price, // Начальная цена для корзины
      modificators: food.modificators ? food.modificators : undefined
    };
  }

  private transformDrink(drink: any): Drink {
    return {
      ...drink,
      labels: drink.labels ? drink.labels.map(getLabelEnumValue) : [],
      unit: getUnitEnumValue(drink.unit), // Преобразование unit
      variants: drink.variants ? drink.variants.map(getVariantValue) : [],
      choise: 0, // Начальный вариант
      count: 0, // Начальное количество для корзины
      totalPrice: drink.price, // Начальная цена для корзины
      modificators: drink.modificators ? drink.modificators : undefined
    };
  }
}

// Вспомогательная функция
function getLabelEnumValue(label: string): Labels {
    switch (label) {
      case 'premiere': return Labels.premiere;
      case 'hit': return Labels.hit;
      case 'spicy': return Labels.spicy;
      case 'vegan': return Labels.vegan;
      case 'alcohol': return Labels.alcohol;
      case 'nonalcohol': return Labels.nonalcohol;
      default: return Labels.unknown;
    }
  }

  function getUnitEnumValue(unit: string): Units {
    switch (unit) {
      case 'weight': return Units.weight;
      case 'volume': return Units.volume;
      case 'unit': return Units.unit;
      case 'size': return Units.size;
      default: return Units.unknown;
    }
  }

function getVariantValue(data: { [key: string]: any }): Variant {
  return {
    index: data['index'] ?? 0,
    title: data['title'] ?? '',
    price: data['price'] ?? null,
    size: data['size'] ?? null,
  };
}