<div class="basket" *ngIf="foods.length > 0 || drinks.length > 0">
  <div class="basket-content">
    <div class="basket-content__price">{{ price }} {{ locale?.currency }}</div>
    <div class="basket-content__items">
      <span *ngIf="foodsCount">{{ foodsCount }} {{ foodsCount === 1 ? 'блюдо' : 'блюда' }}</span>
      <span *ngIf="foodsCount && drinksCount"> и </span>
      <span *ngIf="drinksCount">{{ drinksCount }} {{ drinksCount === 1 ? 'напиток' : 'напитка' }}</span>
    </div>
  </div>

  <div class="basket-button" (click)="onBasketClicked(); $event.stopPropagation()">
    <div class="basket-button__content">
      Корзина
      <mat-icon class="basket-button__icon">restaurant</mat-icon>
    </div>
  </div>
</div>