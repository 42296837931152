import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from "@angular/material/toolbar";

import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from "@angular/material/divider";
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component'
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { connectFirestoreEmulator } from '@firebase/firestore';
import { FormsModule } from '@angular/forms';

import { MenuHomepageComponent } from './pages/menu-homepage/menu-homepage.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';

import { MenuCategoriesComponent } from './components/menu-categories/menu-categories.component';
import { MenuCategoryComponent } from './components/menu-category/menu-category.component';
import { FoodCardComponent } from './components/menu-food-card/food-card.component';
import { DrinkCardComponent } from './components/menu-drink-card/drink-card.component';
import { MenuCategoryPremiereComponent } from './components/menu-category-premiere/menu-category-premiere.component';
import { MenuNavigationComponent } from './components/menu-navigation/menu-navigation.component';
import { RestaurantTitleComponent } from './components/restaurant-title/restaurant-title.component';

import { DataService } from './services/data.service';
import { IntToColorPipe } from './pipes/int-to-color.pipe';
import { SortFoodsPipe } from './pipes/sort-foods.pipe';
import { MenuHeaderComponent } from './components/menu-header/menu-header.component';
import { MenuBasketComponent } from './components/menu-basket/menu-basket.component';
import { MenuBasketOrderComponent } from './components/menu-basket-order/menu-basket-order.component';
import { MenuBasketDialogComponent } from './components/menu-basket-dialog/menu-basket-dialog.component';
import { MenuFoodDialogComponent } from './components/menu-food-dialog/menu-food-dialog.component';
import { MenuDrinkDialogComponent } from './components/menu-drink-dialog/menu-drink-dialog.component';

// food
import { MenuFoodRecomendationDialogComponent } from './components/menu-food-recomendation-dialog/menu-food-recomendation-dialog.component';
import { MenuDrinkRecomendationDialogComponent } from './components/menu-drink-recomendation-dialog/menu-drink-recomendation-dialog.component';

import { MenuModificatorsGridComponent } from './components/menu-modificators-grid/menu-modificators-grid.component';
import { MenuDialogFoodVariantsComponent } from './components/menu-dialog-food-variants/menu-dialog-food-variants.component';
import { MenuRecomendationFoodVariantsComponent } from './components/menu-recomendation-food-variants/menu-recomendation-food-variants.component';
import { MenuRecomendationDrinkVariantsComponent } from './components/menu-recomendation-drink-variants/menu-recomendation-drink-variants.component';
import { MenuDialogDrinkVariantsComponent } from './components/menu-dialog-drink-variants/menu-dialog-drink-variants.component';
import { MenuBasketFoodCardComponent } from './components/menu-basket-food-card/menu-basket-food-card.component';
import { MenuBasketDrinkCardComponent } from './components/menu-basket-drink-card/menu-basket-drink-card.component';
import { MenuRecomendationsComponent } from './components/menu-recomendations/menu-recomendations.component';

// Firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage'
import { environment } from '../environments/environment';
import { MenuShowCardsComponent } from './components/menu-show-cards/menu-show-cards.component';
import { NgOptimizedImage } from '@angular/common';
import { MenuFooterComponent } from './components/menu-footer/menu-footer.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  declarations: [
    AppComponent,
    MenuHomepageComponent,
    LandingpageComponent,
    FoodCardComponent,
    DrinkCardComponent,
    MenuCategoryPremiereComponent,
    MenuCategoryComponent,
    MenuCategoriesComponent,
    MenuBasketComponent,
    MenuBasketOrderComponent,
    MenuBasketDialogComponent,
    MenuFoodDialogComponent,
    MenuFoodRecomendationDialogComponent,
    MenuDrinkRecomendationDialogComponent,
    MenuDrinkDialogComponent,
    MenuNavigationComponent,
    RestaurantTitleComponent,
    FilterDialogComponent,
    IntToColorPipe,
    SortFoodsPipe,
    MenuHeaderComponent,
    MenuModificatorsGridComponent,
    MenuDialogFoodVariantsComponent,
    MenuRecomendationFoodVariantsComponent,
    MenuRecomendationDrinkVariantsComponent,
    MenuDialogDrinkVariantsComponent,
    MenuBasketFoodCardComponent,
    MenuBasketDrinkCardComponent,
    MenuRecomendationsComponent,
    MenuShowCardsComponent,
    MenuFooterComponent,
  ],
  imports: [
    NgOptimizedImage,
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonToggleModule,
    
    // Инициализация Firebase с базой данных и аналитикой
    provideFirebaseApp(() => {
      const defaultApp = initializeApp(environment.firebaseDatabase); // Инициализация Firebase (первичное приложение)
      return defaultApp; // Возвращаем инициализированное приложение
    }),

    // Привязка Firestore к уже инициализированному приложению
    provideFirestore(() => {
      const firestore = getFirestore(); // Получаем Firestore для инициализированного приложения
      if (firestore.app.options.projectId!.indexOf('demo') === 0)
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);

      return firestore;
    }),

    // Привязка Auth
    provideAuth(() => {
      const auth = getAuth(); // Получаем Auth для инициализированного приложения
      if (auth.app.options.projectId!.indexOf('demo') === 0)
        connectAuthEmulator(auth, 'http://127.0.0.1:9099');

      return auth;
    }),

    // Привязка Functions
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),

    // Инициализация Firebase Analytics для аналитики
    provideAnalytics(() => {
      const app = initializeApp(environment.firebaseAnalytics, 'analytics'); // Инициализация для аналитики
      return getAnalytics(app); // Возвращаем Analytics для инициализированного приложения
    })
  ],
  providers: [
    DataService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
