import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Drink } from 'src/interfaces/drink.model';
import { Locale } from 'src/interfaces/locale.model';
import { Variant } from 'src/interfaces/variant.model';

@Component({
  selector: 'app-menu-dialog-drink-variants',
  templateUrl: './menu-dialog-drink-variants.component.html',
  styleUrls: ['./menu-dialog-drink-variants.component.css']
})

export class MenuDialogDrinkVariantsComponent {
  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
  ) {
    this.choise = this.drink?.choise || 0;
    this.locale = this.dataService.locale;
  }

  @Input() drink : Drink | null = null;
  @Output() variantsChange = new EventEmitter(); // Output property

  choise: number;
  locale: Locale | null = null;

  onChoiseClick(): void {
    if (this.drink) {
      console.log('MenuDialogDrinkVariants | onChoiseClick() | index:', this.choise);
      this.drink.choise = this.choise;
      this.variantsChange.emit(); // Emit updated modificators
    }
  }

  ngAfterViewInit() {
    if (this.drink) this.choise = this.drink.choise;
    this.cdr.detectChanges();
  }
}
