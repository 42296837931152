<div class="modificators-title">{{ modificators?.active ? modificators?.title : 'Добавить' }}</div>
<div class="modificators-grid">
    <div *ngFor="let modificator of modificators?.items">
        <mat-card class="modificator-card"
        [ngClass]="{ 'active-border': modificator.count > 0 }"
         *ngIf="modificator" [ngStyle]="{
            'width.px': width,
            'height.px': height,
            }"(click)="onModificatorClick(modificator); $event.stopPropagation();">

            <div class="modificator-choise" *ngIf="modificator.count > 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="selected-icon">
                    <path fill="#000" fill-rule="evenodd" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16m0 2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10" clip-rule="evenodd"></path><path fill="#000" fill-rule="evenodd" d="M16.602 7.864a1 1 0 0 1 .2 1.4l-4.576 6.101c-.061.082-.146.197-.23.29a1.35 1.35 0 0 1-.513.366c-.311.121-.656.121-.967 0a1.35 1.35 0 0 1-.513-.365c-.084-.094-.17-.209-.23-.29l-2.075-2.767a1 1 0 0 1 1.6-1.2l1.701 2.269 4.202-5.604a1 1 0 0 1 1.4-.2" clip-rule="evenodd"></path>
                </svg>
            </div>
            
            <div class="modificator-card-image">
              <img mat-card-image class="mat-card-image"
                [src]="modificator.image ? modificator.image : 'assets/no_photo_mod_ru.png'" alt="{{ modificator.title }}">
            </div>
    
            <div class="modificator-info">
              <div class="modificator-title" [ngStyle]="{
                'font-size.px': height * .11,
              }">{{ modificator.title }}</div>
    
              <!-- Показываем цену -->
              <div class="modificator-price" *ngIf="showPrice(modificator)" [ngStyle]="{
                'font-size.px': height * .12,
                'height.px': height * .1825,
              }">+ {{ modificator.price }} {{locale?.currency}}</div>
    
              <!-- Показываем счетчик -->
              <div class="modificator-count" *ngIf="showCounter(modificator)" [ngStyle]="{
                'width.px': width * 0.8,
                'height.px': height * .1825,
              }">
                <div class="count-decrement" (click)="onModificatorDecrement(modificator); $event.stopPropagation()" [ngStyle]="{
                  'font-size.px': (height * .08).toFixed(0),
                  'width.px': height * .1825,
                  'height.px': height * .1825,
                  'margin': '0',
                  'padding': '0',
                  }">
                  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"  class="light" aria-hidden="true">
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="black" d="M12 1C12 1.41421 11.6642 1.75 11.25 1.75L0.75 1.75C0.335787 1.75 -3.62117e-08 1.41421 0 1C3.62117e-08 0.585786 0.335787 0.25 0.75 0.25L11.25 0.250001C11.6642 0.250001 12 0.585787 12 1Z"></path>
                  </svg>
                </div>
                <div class="count-value" [ngStyle]="{
                  'font-size.px': height * .13,
                }">{{ modificator.count }}</div>
                <div class="count-increment" (click)="onModificatorIncrement(modificator); $event.stopPropagation()" [ngClass]="{'disabled': modificator.count >= modificator.maximum}" [ngStyle]="{
                    'width.px': height * .1825,
                    'height.px': height * .1825,
                  }">
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="light" aria-hidden="true">
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="black" d="M6.8 1.25C6.8 0.835786 6.46421 0.5 6.05 0.5C5.63579 0.5 5.3 0.835786 5.3 1.25V6L0.75 6C0.335787 6 0 6.33579 0 6.75C0 7.16421 0.335787 7.5 0.75 7.5L5.3 7.5V11.75C5.3 12.1642 5.63579 12.5 6.05 12.5C6.46421 12.5 6.8 12.1642 6.8 11.75V7.5L11.25 7.5C11.6642 7.5 12 7.16421 12 6.75C12 6.33579 11.6642 6 11.25 6L6.8 6V1.25Z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </mat-card>
    </div>
  </div>