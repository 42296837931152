import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Food } from "src/interfaces/food.model";
import { Drink } from "src/interfaces/drink.model";
import { Modificators } from "src/interfaces/modificators.model";

@Injectable({
    providedIn: 'root'
  })

export class BasketService {
    private foodsSubject: BehaviorSubject<Food[]> = new BehaviorSubject<Food[]>([]);
    private drinksSubject: BehaviorSubject<Drink[]> = new BehaviorSubject<Drink[]>([]);
    public foods: Observable<Food[]> = this.foodsSubject.asObservable();
    public drinks: Observable<Drink[]> = this.drinksSubject.asObservable();


    /// FOODS
    ///
    fetchFoodFromBasket(food: Food): Food | null{
        console.log('BasketService | fetchFoodFromBasket() ', food.title);
        const currentFoods = this.foodsSubject.getValue();
        const existingFood = currentFoods.find(f => f.id === food.id);
        if (existingFood) {
            return existingFood;
        } else {
            return null;
        }
    }

    pushFoodToBasket(food: Food): void {
        console.log('BasketService | pushFoodToBasket() ', food.title);
    
        // Get the current list of foods in the basket
        const currentFoods = this.foodsSubject.getValue();

        currentFoods.push(food);
    
        // Update the basket with the modified list
        this.foodsSubject.next([...currentFoods]);
    }

    updateFoodInBasket(food: Food): void {
        console.log('BasketService | updateFoodInBasket() ', food.title);
    
        // Get the current list of foods in the basket
        const currentFoods = this.foodsSubject.getValue();
    
        // Find if the food item already exists in the basket
        const index = currentFoods.findIndex(f => f.basketId === food.basketId);
    
        if (index !== -1) {
            // Update the existing food item
            currentFoods[index] = food; // Replace the old food with the new one
        } else {
            // Add the new food item
            currentFoods.push(food);
        }
    
        // Update the basket with the modified list
        this.foodsSubject.next([...currentFoods]);
    }

    removeFoodFromBasket(food: Food): void {
        console.log('BasketService | removeFoodFromBasket() ', food.title);
        const currentFoods = this.foodsSubject.getValue();
        const index = currentFoods.findIndex(f => f.basketId === food.basketId);
        if (index !== -1) {
            currentFoods.splice(index, 1);
            this.foodsSubject.next([...currentFoods]);
        }
    }

    /// DRINKS
    ///
    pushDrinkToBasket(drink: Drink): void {
        console.log('BasketService | pushDrinkToBasket() ', drink.title);
    
        // Get the current list of drinks in the basket
        const currentDrinks = this.drinksSubject.getValue();

        currentDrinks.push(drink);
    
        // Update the basket with the modified list
        this.drinksSubject.next([...currentDrinks]);
    }

    updateDrinkInBasket(drink: Drink): void {
        console.log('BasketService | updateDrinkInBasket() ', drink.title);
    
        // Get the current list of drinks in the basket
        const currentDrinks = this.drinksSubject.getValue();
    
        // Find if the drink item already exists in the basket
        const index = currentDrinks.findIndex(f => f.basketId === drink.basketId);
    
        if (index !== -1) {
            // Update the existing drink item
            currentDrinks[index] = drink; // Replace the old drink with the new one
        } else {
            // Add the new drink item
            currentDrinks.push(drink);
        }
    
        // Update the basket with the modified list
        this.drinksSubject.next([...currentDrinks]);
    }

    removeDrinkFromBasket(drink: Drink): void {
        console.log('BasketService | removeDrinkFromBasket() ', drink.title);
        const currentDrinks = this.drinksSubject.getValue();
        const index = currentDrinks.findIndex(f => f.basketId === drink.basketId);
        if (index !== -1) {
            currentDrinks.splice(index, 1);
            this.drinksSubject.next([...currentDrinks]);
        }
    }
    
}