import { Component, Input } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { DataService } from 'src/app/services/data.service';
import { Food } from 'src/interfaces/food.model';
import { Locale } from 'src/interfaces/locale.model';
import { MatDialog } from '@angular/material/dialog';
import { MenuFoodDialogComponent } from '../menu-food-dialog/menu-food-dialog.component';
import { BasketService } from 'src/app/services/basket.service';
import { Modificators } from 'src/interfaces/modificators.model';

@Component({
  selector: 'app-basket-food-card',
  templateUrl: './menu-basket-food-card.component.html',
  styleUrls: ['./menu-basket-food-card.component.css']
})
export class MenuBasketFoodCardComponent {

  constructor(
    private dataService: DataService,
    private basketService: BasketService,
    private dialog: MatDialog
  ) {
    this.locale = this.dataService.locale;
  }

  locale: Locale | null = null;

  @Input() food: Food | null = null;
  @Input() isLast: boolean = false;

  price: string = '';
  // Размер
  size: number | null = null;
  // Вариант
  variant: string | null = null;
  modificators: Modificators | undefined = undefined;

  ngOnInit(): void {
    if (this.food) {
      // Цена блюда
      if (this.food.variability && this.food.variants.length > this.food.choise) {
        console.log('MenuBasketFoodCard | ngOnInit() | choise:', this.food.choise, ' | count:', this.food.count, ' | price:', this.food.totalPrice);
        this.price = (this.food.variants[this.food.choise].price ?? this.food.price).toLocaleString('ru-RU');
        // Вариант блюда
        this.variant = this.food.variants[this.food.choise].title;
        // Размер блюда
        this.size = (this.food.variants[this.food.choise].size ?? this.food.size);
      } else {
        this.price = this.food.price.toLocaleString('ru-RU');
        this.size = this.food.size;
      }

      this.modificators = typeof this.food.modificators === 'object' ? this.food.modificators : undefined;
      this.modificators = this.modificators?.items?.length ? this.modificators : undefined;
      this.modificators = this.modificators?.items.some((modificator) => modificator.count > 0) ? this.modificators : undefined;
    }
  }

  increment(): void {
    if (!this.food) return;
    this.food.count++;
    this.basketService.updateFoodInBasket(this.food);
  }

  decrement(): void {
    if (!this.food) return;
    if (this.food.count > 1) {
      this.food.count--;
      this.basketService.updateFoodInBasket(this.food);
    } else {
      this.basketService.removeFoodFromBasket(this.food);
    }
  }

  onFoodClicked(): void {
    const dialogRef = this.dialog.open(MenuFoodDialogComponent, {
      panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
      position: { bottom: '0' },
      width: '100%',
      maxWidth: '100%',
      data: cloneDeep(this.food),
    });
  }

  get localSize(): string {
    return this.size ? this.size.toLocaleString('ru-RU', { useGrouping: true }) : '';
  }
}
