import { Injectable } from "@angular/core";
import { Firestore, doc, docData } from "@angular/fire/firestore";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class StoplistService {
    private foodsSubject = new ReplaySubject<string[]>(1);
    public foods$: Observable<string[]> = this.foodsSubject.asObservable();

    private drinksSubject = new ReplaySubject<string[]>(1);
    public drinks$: Observable<string[]> = this.drinksSubject.asObservable();

    private dataSubscription?: Subscription;
    private isInitialized = false;

    constructor(private store: Firestore) {}

    public init(id: string): void {
        if (this.isInitialized) return;
        this.isInitialized = true;

        const docRef = doc(this.store, 'stoplist', id);
        console.log('StoplistService | Initializing subscription for ID:', id);

        this.dataSubscription = docData(docRef).pipe(
            map(data => {
                const stopListData = data as { foods?: string[], drinks?: string[] } || {};
                return {
                    foods: stopListData.foods || [],
                    drinks: stopListData.drinks || []
                };
            }),
            shareReplay(1) // Кешируем последнее значение
        ).subscribe(stopListData => {
            console.log('StoplistService | Data updated:', stopListData);
            this.foodsSubject.next(stopListData.foods);
            this.drinksSubject.next(stopListData.drinks);
        });
    }

    public getFoods(): string[] {
        return this.getCurrentValue(this.foodsSubject);
    }

    public getDrinks(): string[] {
        return this.getCurrentValue(this.drinksSubject);
    }

    private getCurrentValue<T>(subject: ReplaySubject<T>): T {
        let value!: T;
        subject.subscribe(v => value = v).unsubscribe();
        return value;
    }

    public destroy(): void {
        this.dataSubscription?.unsubscribe();
        this.isInitialized = false;
    }
}
