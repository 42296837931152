import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import { BasketService } from 'src/app/services/basket.service';
import { DataService } from 'src/app/services/data.service';
import { ModificatorsService } from 'src/app/services/modificators.service';
import { Food } from 'src/interfaces/food.model';
import { Locale } from 'src/interfaces/locale.model';
import { Modificator, Modificators } from 'src/interfaces/modificators.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-menu-food-dialog',
  templateUrl: './menu-food-dialog.component.html',
  styleUrls: ['./menu-food-dialog.component.css']
})
export class MenuFoodDialogComponent implements OnInit, AfterViewInit{

  constructor(
    public dialogRef: MatDialogRef<MenuFoodDialogComponent>,
    private dataService: DataService,
    private basketService: BasketService,
    private modificatorsService: ModificatorsService,
    private cdr: ChangeDetectorRef, 
    @Inject(MAT_DIALOG_DATA) public data: Food // Принимаем объект Food
  ) {
    this.locale = this.dataService.locale;
    this.food = this.data;
    this.price = ((this.food.count || 1) * this.data.totalPrice).toLocaleString('ru-RU');
    this.original = cloneDeep(this.food);
  }

  @ViewChild('scrollContent') scrollContent!: ElementRef;
  @ViewChild('titleOriginal') titleOriginal!: ElementRef;
  titleOffset: number = 10;
  
  // Свайп
  startY: number = 0;
  currentY: number = 0;
  isSwiping: boolean = false;
  initialHeight: number = 90; // Процент высоты экрана
  minHeight: number = 20; // Процент уменьшения, при котором окно закроется

  food: Food;
  original: Food;
  locale: Locale | null;
  isBasket: boolean = false;
  canUpdate: boolean = false;

  // Текстовое выражение для цены
  price: string;

  // Модификаторы
  modificators: Modificators[] = [];

  ngOnInit(): void {
    if (this.food.basketId) {
      this.isBasket = true;
      this.canUpdate = false;
    } else {
      this.food.count = this.food.count || 1;
      this.original.count = this.food.count || 1;
    }
    
    // Загружаем модификаторы
    this.modificators = (this.food.modificators as (Modificators | string)[]).filter((item): item is Modificators => typeof item === 'object');
  }

  ngAfterViewInit() {
    this.scrollContent.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    this.titleOffset = this.titleOriginal.nativeElement.offsetTop - 4;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.isSwiping = true;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (!this.isSwiping) return;

    this.currentY = event.touches[0].clientY;
    const swipeDistance = this.currentY - this.startY;

    const content = document.querySelector('.food-dialog-content')!;
    const scrollPosition = content.scrollTop;

    // Проверяем, находится ли контент внизу
    const isContentAtTop = scrollPosition == 0;

    if (swipeDistance > 0 && isContentAtTop) { // Свайп вниз и контент прокручен до конца
      event.preventDefault(); // Предотвращаем стандартное поведение, если происходит свайп вниз и контент внизу
      const newHeight = this.initialHeight - (swipeDistance / window.innerHeight * 100);
      this.updateDialogHeight(newHeight);

      // Предотвращаем обновление страницы
      if (newHeight < this.initialHeight) {
        event.preventDefault();
      }
    }
  }

  onScroll(event: any) {
    const scrollOffset = this.scrollContent.nativeElement.scrollTop;
    
    const title = document.querySelector('.food-dialog-title__header')!;
    if (scrollOffset > this.titleOffset) {
      title.classList.add('show');
    } else {
      title.classList.remove('show');
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.isSwiping = false;
    const swipeDistance = this.currentY - this.startY;
    const swipePercentage = swipeDistance / window.innerHeight * 100;

    if (swipePercentage > this.minHeight) {
      this.close();
    } else {
      this.resetDialogHeight();
    }
  }

  updateDialogHeight(height: number) {
    const dialog = document.querySelector('.food-dialog-container') as HTMLElement;
    if (dialog) {
      dialog.style.height = `${Math.max(height, 0)}vh`;
    }
  }

  resetDialogHeight() {
    const dialog = document.querySelector('.food-dialog-container') as HTMLElement;
    if (dialog) {
      dialog.style.height = `${this.initialHeight}vh`;
    }
  }

  onModificatorsChange() {
    console.log('MenuFoodDialog | onModificatorsChange()');
    this.updateTolalPrice();
    this.price = (this.food.count * this.food.totalPrice).toLocaleString('ru-RU');
    this.checkCanUpdateBasket();
  }

  onVariantsChange() {
    console.log('MenuFoodDialog | onVariantsChange()')
    this.updateTolalPrice();
    this.price = (this.food.count * this.food.totalPrice).toLocaleString('ru-RU');
    this.checkCanUpdateBasket();
  }

  increment(): void {
    this.food.count++;
    this.price = (this.food.count * this.food.totalPrice).toLocaleString('ru-RU');
    this.checkCanUpdateBasket();
  }

  decrement(): void {
    if (this.food.count > 1) {
      this.food.count--;
      this.price = (this.food.count * this.food.totalPrice).toLocaleString('ru-RU');
      this.checkCanUpdateBasket();
    }
  }

  // Отписка от событий при уничтожении компонента
  ngOnDestroy() {
    this.scrollContent.nativeElement.removeEventListener('scroll', this.onScroll.bind(this));
  }

  buttonPushRemoveFoodBasket(): void {
    if (!this.isBasket) {

      // Добавляем блюдо в корзину
      console.log('MenuFoodDialog | buttonPushRemoveFoodBasket() | pushFoodToBasket');
      this.food.basketId = uuidv4();
      this.basketService.pushFoodToBasket(this.food);
    } else {
      if (this.canUpdate) {

        // Обновляем блюдо в корзине
        console.log('MenuFoodDialog | buttonPushRemoveFoodBasket() | pushFoodToBasket');
        this.basketService.updateFoodInBasket(this.food);
      } else {

        // Удаляем блюдо из корзины
        console.log('MenuFoodDialog | buttonPushRemoveFoodBasket() | removeFoodDromBasket');
        this.basketService.removeFoodFromBasket(this.food);
      }
    } 
    this.close();
  }

  close(): void {
    const dialog = document.querySelector('.food-dialog-container');
    if (dialog) {
      dialog.classList.add('slide-down-animation');
      setTimeout(() => this.dialogRef.close(), 250); // Задержка для анимации
    }
  }

  updateTolalPrice(): void {
    let value = 0;
    this.food.modificators.forEach(modificators => (modificators as Modificators).items.forEach(item => {
        if (item.count != null && item.count > 0) {
            value += item.price * item.count
        }
        }
    ));

    if (this.food.variants) {
        value += this.food.variants[this.food.choise].price ?? 0;
    }

    // Обновляем общую цену одной единицы
    this.food.totalPrice = this.food.price + value;
    console.log('Food | updateTolal() | count:', this.food.count, ' | price:', this.food.totalPrice);
  }

  private checkCanUpdateBasket(): void {
    if (this.isBasket) {

      if (this.food.choise != this.original.choise) {
        this.canUpdate = true;
        console.log('MenuFoodDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);
        return
      }

      if (this.food.count != this.original.count) {
        this.canUpdate = true;
        console.log('MenuFoodDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);
        return
      }


      // Проверяем, есть ли изменения в модификаторах
      const modsCurrent = this.food.modificators;
      const modsOriginal = this.original.modificators;

      if (modsOriginal && modsOriginal.length > 0) {
        
        // Проход по группам
        for (let i = 0; i < modsOriginal.length; i++) {

          const mod1 = modsOriginal[i];
          const mod2 = modsCurrent[i];

          if (typeof mod1 === 'object' && typeof mod2 === 'object') {
            // console.log('MenuFoodDialog | checkCanUpdateBasket() | compare:', mod1.title);
            
            // Проход по элементам
            for (let j = 0; i < mod1.items.length; j++) {
              
              // Проверяем количество
              const item1 = mod1.items[j];
              const item2 = mod2.items[j];

              // console.log('MenuFoodDialog | checkCanUpdateBasket() | compare:', item1.title, ' | count:', item1.count, ' with:', item2.title, ' | count:', item2.count);

              if (item1.count != item2.count) {
                this.canUpdate = true;
                console.log('MenuFoodDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);
                return
              }
            }
          }
        }
      }
      
      this.canUpdate = false;
      console.log('MenuFoodDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);

    } else {
      this.canUpdate = false;
    }
  }

}

