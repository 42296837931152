<div class="food-dialog-container">
  
  <div class="food-dialog-header"></div>

  <div mat-icon-button (click)="close(); $event.stopPropagation()" class="close-button">
    <mat-icon class="close-icon">close</mat-icon>
  </div>

  <div class="food-dialog-title__header">{{ data.title }}</div>

  <div class="food-dialog-content" #scrollContent>
    <div class="image-container">
      
      <img mat-card-image
        [src]="data.image ? data.image : 'assets/no_photo_ru.png'"
        alt="Photo of the food"
        id="mat-card-image"/>
    </div>

    <!-- Наименование блюда -->
    <div class="food-dialog-title" #titleOriginal>{{ data.title }}</div>

    <!-- Описание блюда -->
    <div class="food-dialog-description">{{ data.description }}</div>

    <!-- Вес и время приготовления -->
    <div class="food-dialog-info">
      <div class="food-dialog-info__weight" *ngIf="data.size">{{ data.size }} {{ locale?.units?.get(data.unit) }}</div>
      <div class="food-dialog-info__weight" *ngIf="!data.size">Вес не указан</div>
      <div class="food-dialog-info__time" *ngIf="data.prepare">{{ data.prepare }} {{ locale?.time }}</div>
    </div>
  
    <!-- Варианты блюда -->
    <div *ngIf="food.variant && food.variants.length">
      <app-menu-dialog-variants [food]="food" (variantsChange)="onVariantsChange()"></app-menu-dialog-variants>
    </div>

    <!-- Слайдер модификаторов -->
    <div *ngIf="modificators.length" style="padding-top: 6px;"></div>
    <div *ngIf="modificators.length">
      <div *ngFor="let modificator of modificators">
        <app-modificators-slider [modificators]="modificator" (modificatorsChange)="onModificatorsChange()"></app-modificators-slider>
      </div>
    <div *ngIf="modificators.length" style="padding-bottom: 6px;"></div>
  </div>

  <div class="food-dialog-footer"></div>
  
  
  <!-- Кнопки для заказа -->
  <div class="food-dialog-order">
    <!-- Блок управления количеством -->
    <div class="order-quantity">
      <button class="bascket-count-dec" (click)="decrement(); $event.stopPropagation()" [ngStyle]="{'color': food.count > 1 ? '#000000' : '#9e9e9e'}">−</button>
      <div class="order-button__count">{{ food.count }}</div>
      <button class="bascket-count-inc" (click)="increment()">+</button>
    </div>
    <!-- Кнопка добавления в корзину -->
    <button class="basket-button" (click)="buttonPushRemoveFoodBasket(); $event.stopPropagation()" 
      [ngStyle]="{'color': isBasket && !canUpdate ? 'red' : 'black'}">
      <span *ngIf="!isBasket">Добавить</span>
      <span *ngIf="isBasket && !canUpdate">Убрать</span>
      <span *ngIf="isBasket && canUpdate">Обновить</span>
       · {{ price }} {{ locale?.currency }}
    </button>
  </div>
</div>

