import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { map, Subscription, tap } from 'rxjs';
import { ShowCardsService } from 'src/app/services/show-cards.service';
import { ShowCard } from 'src/interfaces/show-cards.model';

@Component({
  selector: 'app-menu-events',
  templateUrl: './menu-show-cards.component.html',
  styleUrls: ['./menu-show-cards.component.css']
})
export class MenuShowCardsComponent {
  constructor(
    private showCardsService: ShowCardsService, 
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    this.showCards = [];
    this.showCardsSubscription = new Subscription();

    // Получаем ширину экрана
    this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this.vw = Math.min(this.vw, 480);

    this.width = 90;
    this.height = 115;
  }

  showCards: ShowCard[];
  private showCardsSubscription: Subscription;

  private vw: number;
  width: number;
  height: number;

  fullscreenImage: string | null = null; // Полноэкранное изображение

  ngOnInit(): void {
    this.showCardsSubscription = this.showCardsService.showCards$.pipe(
      map(showCards => {
        const now = new Date();
        return showCards.filter(showCard => 
          showCard.deadline === null || showCard.deadline >= now
        );
      }),
      tap(filtered => {
        console.log('MenuShowCards | ngOnInit() | subscribe() | showCards: ', filtered.length);
        if (filtered.length) {
          this.showCards = filtered.filter(showCard => showCard.active);
        } else {
          this.showCards = [];
        }
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  ngAfterViewInit() {
    this.width = (Math.min(this.vw || 480) - 64) / 3.5;
    this.height = this.width * 1.27;
    this.cdr.detectChanges(); // Обновляем Angular-поток изменений
  }

  ngOnDestroy(): void {
    this.showCardsSubscription?.unsubscribe();
  }

  openFullscreen(imageUrl: string): void {
    this.fullscreenImage = imageUrl;
    this.renderer.addClass(document.body, 'no-scroll'); // Отключаем скролл
  }

  closeFullscreen(event?: Event): void {
    if (event) {
      event.stopPropagation(); // Предотвращаем всплытие клика
    }
    this.fullscreenImage = null;
    this.renderer.removeClass(document.body, 'no-scroll'); // Включаем скролл обратно
  }
}
