<div class="recomendations-container" *ngIf="recommendedFoods || recommendedDrinks" >
    <div class="recomendations-container__title">{{ title }}</div>
    <div class="recomendations-slider"
        [ngStyle]="{
            'height.px': height + 4,
        }">

        <!-- foods -->
        <div *ngFor="let food of recommendedFoods">
            <mat-card class="recomendation-slider__card"  [ngStyle]="{
                    'width.px': width,
                    'height.px': height,
                    }" (click)="onFoodClicked(food); $event.stopPropagation()">
                <img mat-card-image class="recomendation-card__image" [src]="food.image" alt="Food">
                <div class="card-info">
                    <div class="card-info__title">{{ food.title }}</div>
                    <div class="card-info__price">{{ food.variability && food.variants.length ? 'от ' : '' }}{{
                        food.price }} {{ locale?.currency }}</div>
                </div>
                <!-- basket -->
                <div class="card-info-basket" *ngIf="getBasketFood(food) as basketItem">
                    <img src="assets/basket.svg" alt="basket" class="card-info-basket__icon" />
                    <span class="card-info-basket__count">{{ basketItem.count }}</span>
                </div>
            </mat-card>
        </div>

        <!-- drinks -->
        <div *ngFor="let drink of recommendedDrinks" >
            <mat-card  class="recomendation-slider__card" [ngStyle]="{
                    'width.px': width,
                    'height.px': height,
                    }" (click)="onDrinkClicked(drink); $event.stopPropagation()">
                <img mat-card-image class="recomendation-card__image" [src]="drink.image" alt="Drink">
                <div class="card-info">
                    <div class="card-info__title">{{ drink.title }}</div>
                    <div class="card-info__price">{{ drink.variability && drink.variants.length ? 'от ' : '' }}{{
                        drink.price }} {{ locale?.currency }}</div>
                </div>
                <!-- basket -->
                <div class="card-info-basket" *ngIf="getBasketDrink(drink) as basketItem">
                    <img src="assets/basket.svg" alt="basket" class="card-info-basket__icon" />
                    <span class="card-info-basket__count">{{ basketItem.count }}</span>
                </div>
            </mat-card>
        </div>
    </div>
</div>