import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { DataService } from 'src/app/services/data.service';
import { Drink } from 'src/interfaces/drink.model';
import { Food } from 'src/interfaces/food.model';
import { Locale } from 'src/interfaces/locale.model';

@Component({
  selector: 'app-menu-basket-dialog',
  templateUrl: './menu-basket-dialog.component.html',
  styleUrls: ['./menu-basket-dialog.component.css']
})
export class MenuBasketDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<MenuBasketDialogComponent>,
    private dataService: DataService,
    private basketService: BasketService
  ) {
    this.locale = this.dataService.locale;
  }

  @ViewChild('scrollContent') scrollContent!: ElementRef;

  locale: Locale | null;
  foods: Food[] | null = null;
  drinks: Drink[] | null = null;

  private basketFoodsSubscription: Subscription = new Subscription();
  private basketDrinksSubscription: Subscription = new Subscription();

  // Свайп
  startY: number = 0;
  currentY: number = 0;
  isSwiping: boolean = false;
  initialHeight: number = 100; // Процент высоты экрана
  minHeight: number = 20; // Процент уменьшения, при котором окно закроется

  ngOnInit(): void {
      this.basketFoodsSubscription = this.basketService.foods.subscribe((data: Food[]) => {
        this.foods = data;
        console.log('MenuBasketDialog | ngOnInit() | foods:', this.foods.length ? this.foods.map(x => x.title).join(', ') : 'empty');
        if (this.drinks && this.drinks.length == 0 && this.foods.length == 0) {
          this.close();
        }
      });
  
      this.basketDrinksSubscription = this.basketService.drinks.subscribe((data: Drink[]) => {
        this.drinks = data;
        console.log('MenuBasketDialog | ngOnInit() | drinks:', this.drinks.length ? this.drinks.map(x => x.title).join(', ') : 'empty');
        if (this.drinks.length == 0 && this.foods && this.foods.length == 0) {
          this.close();
        }
      });
    }

  ngAfterViewInit() {
    this.scrollContent.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
  }
  
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.isSwiping = true;
  }
  
  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (!this.isSwiping) return;

    this.currentY = event.touches[0].clientY;
    const swipeDistance = this.currentY - this.startY;

    const content = document.querySelector('.basket-dialog-content')!;
    const scrollPosition = content.scrollTop;

    // Проверяем, находится ли контент внизу
    const isContentAtTop = scrollPosition == 0;

    if (swipeDistance > 0 && isContentAtTop) { // Свайп вниз и контент прокручен до конца
      event.preventDefault(); // Предотвращаем стандартное поведение, если происходит свайп вниз и контент внизу
      const newHeight = this.initialHeight - (swipeDistance / window.innerHeight * 100);
      this.updateBasketHeight(newHeight);

      // Предотвращаем обновление страницы
      if (newHeight < this.initialHeight) {
        event.preventDefault();
      }
    }
  }
  
  onScroll(event: any) {
    const scrollOffset = this.scrollContent.nativeElement.scrollTop;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.isSwiping = false;
    const swipeDistance = this.currentY - this.startY;
    const swipePercentage = swipeDistance / window.innerHeight * 100;

    if (swipePercentage > this.minHeight) {
      this.close();
    } else {
      this.resetDialogHeight();
    }
  }
  
  updateBasketHeight(height: number) {
    const dialog = document.querySelector('.basket-dialog-container') as HTMLElement;
    if (dialog) {
      dialog.style.height = `${Math.max(height, 0)}vh`;
    }
  }

  resetDialogHeight() {
    const dialog = document.querySelector('.basket-dialog-container') as HTMLElement;
    if (dialog) {
      dialog.style.height = `${this.initialHeight}vh`;
    }
  }

  // Отписка от событий при уничтожении компонента
  ngOnDestroy() {
    this.scrollContent.nativeElement.removeEventListener('scroll', this.onScroll.bind(this));
      this.basketFoodsSubscription?.unsubscribe();
      this.basketDrinksSubscription?.unsubscribe();
  }

  close(): void {
    console.log('MenuBasketDialog | close()');
    const dialog = document.querySelector('.basket-dialog-container');
    if (dialog) {
      dialog.classList.add('slide-down-animation');
      setTimeout(() => this.dialogRef.close(), 250); // Задержка для анимации
    }
  }
}
