import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Locale } from 'src/interfaces/locale.model';
import { Modificator, Modificators } from 'src/interfaces/modificators.model';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-modificators-slider',
  templateUrl: './modificators-slider.component.html',
  styleUrls: ['./modificators-slider.component.css']
})
export class ModificatorsSliderComponent{
  constructor(
    private dataService: DataService, 
    private cdr: ChangeDetectorRef, 
  ) {
    this.slidesPerView = 4;
    this.locale = this.dataService.locale;
    // Получаем ширину экрана
    this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this.width = 105;
    this.height = 140;
   }

  @Input() modificators : Modificators | null = null;
  @Output() modificatorsChange = new EventEmitter(); // Output property

  slidesPerView: number;
  locale: Locale | null = null;
  vw: number;
  width: number;
  height: number;

  ngAfterViewInit() {
    this.width = (Math.min(this.vw || 480) - 72) / 3;
    this.height = this.width * 1.33;
    // console.log('ModificatorsSliderComponent | ngAfterViewInit() | vw:', this.vw, ' | slidesPerView:', this.slidesPerView);
    this.cdr.detectChanges();
  }  

  onModificatorIncrement(modificator: Modificator) {
    if (modificator.max == null || modificator.count < modificator.max) {
      modificator.count++;
    }
    this.emitChanges(); // Emit changes
    console.log('ModificatorsSlider | onModificatorIncrement() | modificator: ', modificator.title, ' | count:', modificator.count);
  }

  onModificatorDecrement(modificator: Modificator) {
    if (modificator.count > 0) modificator.count--;
    this.emitChanges(); // Emit changes
    console.log('ModificatorsSlider | onModificatorDecrement() | modificator: ', modificator.title, ' | count:', modificator.count);
  }

  private emitChanges() {
    if (this.modificators) {
      this.modificatorsChange.emit(); // Emit updated modificators
    }
  }
}
