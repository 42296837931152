<div class="food-basket-card-container" [ngClass]="{ 'last-card': isLast }" *ngIf="food">
    <div class="food-basket-card">
        <div class="food-basket-info">
            <p class="food-basket-title" (click)="onFoodClicked(); $event.stopPropagation()">{{ food.title }}</p>
            <p class="food-basket-price">
                {{ price }} {{ locale?.currency }}
                <span class="food-basket-variant" *ngIf="variant">{{ variant }}</span>
                <span class="food-basket-size" *ngIf="size">{{ localSize }} {{ locale?.units?.get(food.unit) }}</span>
            </p>
            <div class="food-basket-modificators" *ngIf="modificators" (click)="onFoodClicked(); $event.stopPropagation()">
                <div class="food-basket-modificator">
                    <p>{{ modificators.active ? modificators.title : 'Добавить' }}:</p>
                    <div *ngFor="let item of modificators.items">
                        <div class="food-basket-modificator-item" *ngIf="item.count > 0">
                            {{ item.count }} · {{ item.title }} · {{ item.count * item.price }} {{ locale?.currency }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Блок управления количеством -->
            <div class="food-basket-counter">
                <button class="counter-button-dec" (click)="decrement(); $event.stopPropagation()">−</button>
                <span class="food-basket-counter__value">{{ food.count }}</span>
                <button class="counter-button-inc" (click)="increment(); $event.stopPropagation()">+</button>
            </div>
        </div>
        <div class="food-basket-image" (click)="onFoodClicked(); $event.stopPropagation()">
            <img [src]="food.image ? food.image : 'assets/no_photo_ru.png'" alt="{{ food.title }}" />
        </div>
    </div>
</div>