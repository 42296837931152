import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuHomepageComponent } from './pages/menu-homepage/menu-homepage.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';

const routes: Routes = [
  // { path: '', component: LandingpageComponent },
  { path: '', component: MenuHomepageComponent },
  { path: ':restaurantId/:tableId', component: MenuHomepageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
