<div class="food-card-container" *ngIf="food" (click)="stoplist ? null : onCardClicked(); $event.stopPropagation()">
  <mat-card class="food-card">
    <div class="image-container">
      <img mat-card-image [class.stop-list]="stoplist"
        [src]="food.image ? food.image : 'assets/no_photo_ru.png'"
        alt="Photo of the food"
        id="mat-card-image"
      />
      <!-- Продуктовые баннеры -->
      <div class="banners" [class.stop-list]="stoplist">
        <span class="hit-banner" *ngIf="food.labels.includes(Labels.hit)">хит</span>
        <span class="spicy-banner" *ngIf="food.labels.includes(Labels.spicy)">острое</span>
        <span class="premiere-banner" *ngIf="food.labels.includes(Labels.premiere)">новинка</span>
        <span class="veget-banner" *ngIf="food.labels.includes(Labels.vegan)">вегетарианское</span>
        <span class="nonalcohol-banner" *ngIf="food.labels.includes(Labels.nonalcohol)">безалкогольное</span>
        <span class="alcohol-banner" *ngIf="food.labels.includes(Labels.alcohol)">алкоголь</span>
      </div>

      <!-- Служебные баннеры -->
      <div class="info-banner">
        <span class="stoplist-banner" *ngIf="stoplist">закончилось</span>
      </div>
    </div>

    <mat-card-content class="food-info" [class.stop-list]="stoplist">
      <div class="food-info__title">
        <div class="food-title" [innerHTML]="food.title"></div>
      </div>

      <mat-card-title class="food-info__price" [class.stop-list]="stoplist">
        {{ price }} {{ locale?.currency }}  <span class="food-size" [class.stop-list]="stoplist" *ngIf="food.size">{{ food.size }} {{ locale?.units?.get(food.unit) }}</span>
      </mat-card-title>

      <div class="food-info__count" *ngIf="basketFood">
        <span class="food-count">{{ basketFood.count }}</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>