import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category } from 'src/interfaces/category.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationCategoryService {
  private categoriesSubject = new BehaviorSubject<Category[]>([]);
  public categories$: Observable<Category[]> = this.categoriesSubject.asObservable();
  private currentCategoryIdSubject = new BehaviorSubject<string | undefined>(undefined);
  public currentCategoryId$: Observable<string | undefined> = this.currentCategoryIdSubject.asObservable();

  constructor() {}

  addCategory(category: Category): void {
    const currentCategories = this.categoriesSubject.getValue();
    if (!currentCategories.some(c => c.id === category.id)) {
      this.categoriesSubject.next([...currentCategories, category]);
    }
  }

  removeCategory(categoryId: string): void {
    const currentCategories = this.categoriesSubject.getValue();
    const updatedCategories = currentCategories.filter(c => c.id !== categoryId);
    this.categoriesSubject.next(updatedCategories);
  }
}
