
import { Component, OnInit, Input } from '@angular/core';
import { Food } from 'src/interfaces/food.model';
import { Labels, Units } from 'src/interfaces/enums.model';
import { DataService } from 'src/app/services/data.service';
import { Restaurant } from 'src/interfaces/restaurant.model';
import { Locale } from 'src/interfaces/locale.model';
import { BasketService } from 'src/app/services/basket.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuFoodDialogComponent } from '../menu-food-dialog/menu-food-dialog.component';
import { ScrollService } from 'src/app/services/scroll.service';

import { cloneDeep, debounce } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-food-card',
  templateUrl: './food-card.component.html',
  styleUrls: ['./food-card.component.css'],
})

export class FoodCardComponent implements OnInit{
  constructor(private dataService: DataService, 
    private basketService: BasketService,
    private scrollService: ScrollService,
    private dialog: MatDialog
  ) {
    this.restaurant = this.dataService.restaurant;
    this.locale = this.dataService.locale;
    this.basketFoodsSubscription = new Subscription();
  }
  restaurant: Restaurant | null = null;
  locale: Locale | null = null;
  
  price: string = '';
  variability: boolean = false;
  basketFood: Food | null = null;

  @Input() food: Food | null = null;
  @Input() stoplist: boolean = false;

  Labels = Labels;

  private basketFoodsSubscription: Subscription;

  ngOnInit(): void {
    if (this.food) {
      this.price = this.food.price.toLocaleString('ru-RU');
      this.variability = this.food?.variants?.some(v => v.price !== null);

      // Подписка на изменения корзины
      this.basketFoodsSubscription = this.basketService.foods.subscribe((data: Food[]) => {
        let basketFoods = data;
        const existingFood = basketFoods.find(f => f.id === this.food?.id);
        
        if (existingFood) {
          this.basketFood = existingFood;
        } else {
          this.basketFood = null;
        }
      });
    }
  }

  onCardClicked(): void {
    if (this.stoplist || !this.food) {
      return;
    }

    // Затемняем строку состояния перед открытием модального окна
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#a6a6ae');

    this.scrollService.setScrollEnabled(false);
    const dialogRef = this.dialog.open(MenuFoodDialogComponent, {
      panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
      position: { bottom: '0', left: '0', right: '0' },
      width: '100%',
      maxWidth: '100%',
      data: this.basketFood ? cloneDeep(this.basketFood) : cloneDeep(this.food),
    });

    dialogRef.afterClosed().subscribe(() => {
      this.scrollService.setScrollEnabled(true);
      
      // Возвращаем исходный цвет строки состояния
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#f5f5ff');
    });
  }

  getImageSrc(image?: string): string {
    return image && image.trim() ? image : 'assets/no_photo_ru.png';
  }

  ngOnDestroy(): void {
    this.basketFoodsSubscription?.unsubscribe();
  }
}
