<div class="modificators-slider" *ngIf="modificators?.items?.length">
  <div class="modificators-title">{{ modificators?.title }}</div>
  <swiper-container slides-per-view="3"  navigation="false" pagination="false" scrollbar="true" [spaceBetween]="16">
    <swiper-slide *ngFor="let modificator of modificators?.items">
      <mat-card class="modificator-card" *ngIf="modificator" [ngStyle]="{
        'width.px': width,
        'height.px': height,
        }" (click)="modificator.count == 0 ? onModificatorIncrement(modificator) : null; $event.stopPropagation()">
        
        <div class="modificator-card-image">
          <img mat-card-image id="mat-card-image" [src]="modificator.image" alt="{{ modificator.title }}">
        </div>

        <div class="modificator-info">
          <div class="modificator-title" [ngStyle]="{
            'font-size.px': height * .11,
          }">{{ modificator.title }}</div>

          <!-- Начальное состояние -->
          <div class="modificator-price" *ngIf="modificator.count == 0" [ngStyle]="{
            'font-size.px': height * .12,
            'height.px': height * .1825,
          }">+ {{ modificator.price }} {{locale?.currency}}</div>

          <!-- Количество больше нуля -->
          <div class="modificator-count" *ngIf="modificator.count > 0" [ngStyle]="{
            'width.px': width * 0.8,
            'height.px': height * .1825,
          }">
            <div class="count-decrement" (click)="onModificatorDecrement(modificator); $event.stopPropagation()" [ngStyle]="{
              'font-size.px': (height * .08).toFixed(0),
              'width.px': height * .1825,
              'height.px': height * .1825,
              'margin': '0',
              'padding': '0',
              }">
              <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg"  class="light" aria-hidden="true">
                <path fill-rule="evenodd" clip-rule="evenodd" fill="black" d="M12 1C12 1.41421 11.6642 1.75 11.25 1.75L0.75 1.75C0.335787 1.75 -3.62117e-08 1.41421 0 1C3.62117e-08 0.585786 0.335787 0.25 0.75 0.25L11.25 0.250001C11.6642 0.250001 12 0.585787 12 1Z"></path>
              </svg>
            </div>
            <div class="count-value" [ngStyle]="{
              'font-size.px': height * .14,
            }">{{ modificator.count }}</div>
            <div class="count-increment" (click)="onModificatorIncrement(modificator); $event.stopPropagation()" [ngStyle]="{
                'width.px': height * .1825,
                'height.px': height * .1825,
              }">
              <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="light" aria-hidden="true">
                <path fill-rule="evenodd" clip-rule="evenodd" fill="black" d="M6.8 1.25C6.8 0.835786 6.46421 0.5 6.05 0.5C5.63579 0.5 5.3 0.835786 5.3 1.25V6L0.75 6C0.335787 6 0 6.33579 0 6.75C0 7.16421 0.335787 7.5 0.75 7.5L5.3 7.5V11.75C5.3 12.1642 5.63579 12.5 6.05 12.5C6.46421 12.5 6.8 12.1642 6.8 11.75V7.5L11.25 7.5C11.6642 7.5 12 7.16421 12 6.75C12 6.33579 11.6642 6 11.25 6L6.8 6V1.25Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </mat-card>
    </swiper-slide>
  </swiper-container>
</div>

