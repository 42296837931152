
import { Component, OnChanges, Input, SimpleChanges} from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Food } from 'src/interfaces/food.model';
import { Drink } from 'src/interfaces/drink.model';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/interfaces/category.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-premiere',
  templateUrl: './menu-category-premiere.component.html',
  styleUrls: ['./menu-category-premiere.component.css'],
}) 
export class MenuCategoryPremiereComponent {
  constructor(
    private productService: ProductService,
    private categoryService: CategoryService
  ) {
    this.categories = [];
    this.foods = [];
    this.drinks = [];
    this.subscription = new Subscription();
  }

  foods: Food[];
  drinks: Drink[];
  categories: Category[];
  subscription: Subscription;

  ngOnInit(): void {
    console.log('MenuCategoryPremiere | ngOnInit() | categories: ', this.categories?.length);
    this.subscription = this.categoryService.categories().subscribe(categories => {
      if (categories) {
        console.log('MenuCategoryPremiere | ngOnInit() | subscription(categories) | categories: ', categories?.length , ' : ' ,categories.map((category: Category) => category.title).join(', '));
        this.categories = categories.sort((a, b) => a.index - b.index);

        // foods
        this.productService.premiereFoods(this.categories.map((category: Category) => category.id) ?? []).subscribe(foods => {
          console.log('MenuCategoryPremiere | ngOnInit() | subscription(categories) | premiereFoods: ', foods?.length);
          if (foods) {
            this.foods = foods.slice().sort((a, b) => a.index - b.index);
          }
        });

        // drinks
        this.productService.premiereDrinks(this.categories.map((category: Category) => category.id) ?? []).subscribe(drinks => {
        console.log('MenuCategoryPremiere | ngOnInit() | subscription(categories) | premiereDrinks: ', drinks?.length);
          if (drinks) {
            this.drinks = drinks.slice().sort((a, b) => a.index - b.index);
          } 
        });
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categories'] && changes['categories'].currentValue) {
      console.log('CategoryPremiereComponent | ngOnChanges() | categories: ', this.categories?.map((category: Category) => category.title).join(', '));
      // foods
      this.productService.premiereFoods(this.categories.map((category: Category) => category.id) ?? []).subscribe(foods => {
        console.log('CategoryPremiereComponent | ngOnChanges() | foods: ', foods?.length);
        if (foods) {
          this.foods = foods.slice().sort((a, b) => a.index - b.index);
        }
      });

      // drinks
      this.productService.premiereDrinks(this.categories.map((category: Category) => category.id) ?? []).subscribe(drinks => {
        console.log('CategoryPremiereComponent | ngOnChanges() | drinks: ', drinks?.length);
        if (drinks) {
          this.drinks = drinks.slice().sort((a, b) => a.index - b.index);
        } else {
          this.drinks = [];
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
