import { Injectable } from "@angular/core";
import {
  Firestore,
  doc,
  docData
} from "@angular/fire/firestore";
import { BehaviorSubject, Observable, of, forkJoin } from "rxjs";
import { map, tap, shareReplay, catchError } from "rxjs/operators";
import { Modificators } from "src/interfaces/modificators.model";

@Injectable({
  providedIn: 'root'
})
export class ModificatorsService {
  private modificatorsMap = new Map<string, Observable<Modificators>>(); // Кешируем потоки
  private modificatorsSubject = new BehaviorSubject<Modificators[]>([]);

  constructor(private store: Firestore) {}

  /**
   * Подписка на обновления модификатора по ID.
   * Если подписка уже существует — возвращаем ее, иначе создаем новую.
   */
  public modificators(id: string): Observable<Modificators> {
    if (!this.modificatorsMap.has(id)) {
      // Подписываемся на обновления
      console.log('ModificatorsService | modificators() ', id);
      const modificator$ = this.fetchModificatorFromFirebase(id).pipe(
        tap(modificator => {
          console.log('ModificatorsService | modificators() ', modificator);
          this.updateCache(modificator);
        }), // Обновляем кэш при каждом изменении
        shareReplay(1) // Кешируем последнее значение
      );

      this.modificatorsMap.set(id, modificator$);
    }

    // Возвращаем поток
    return this.modificatorsMap.get(id)!;
  }

  /**
   * Запрос к Firestore с подпиской на изменения.
   */
  private fetchModificatorFromFirebase(id: string): Observable<Modificators> {
    const modificatorDocRef = doc(this.store, 'modificators', id);
    return docData(modificatorDocRef, { idField: 'id' }).pipe(
      map((modificator: any) => this.processModificator(modificator)),
      catchError(error => {
        console.error('Error fetching modificator:', error);
        return of(null);
      })
    ) as Observable<Modificators>;
  }

  /**
   * Обработка модификатора перед добавлением в кэш.
   */
  private processModificator(modificator: Modificators): Modificators {
    modificator.items = modificator.items.map(item => ({
      ...item,
      count: item.count ?? 0,
      maximum: item.maximum ?? 1,
    }));
    return modificator;
  }

  /**
   * Обновление кеша, если модификатор изменился.
   */
  private updateCache(modificator: Modificators): void {
    const currentModificators = this.modificatorsSubject.getValue();
    const index = currentModificators.findIndex(m => m.id === modificator.id);

    if (index === -1) {
      this.modificatorsSubject.next([...currentModificators, modificator]);
    } else {
      currentModificators[index] = modificator;
      this.modificatorsSubject.next([...currentModificators]);
    }
  }

  /**
   * Получение списка модификаторов из кэша по массиву ID.
   */
  public getModificators(list: string[]): Modificators[] {
    return this.modificatorsSubject.getValue().filter(m => list.includes(m.id));
  }

  /**
   * Получение всех модификаторов для списка ID с подпиской на обновления.
   */
  public getModificatorsAsync(list: string[]): Observable<Modificators[]> {
    const modificators$ = list.map(id => this.modificators(id));
    return forkJoin(modificators$);
  }
}