import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from "@angular/material/toolbar";
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage'
import { environment } from '../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from "@angular/material/divider";
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component'
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { connectFirestoreEmulator } from '@firebase/firestore';
import { FormsModule } from '@angular/forms';

import { MenuHomepageComponent } from './pages/menu-homepage/menu-homepage.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';

import { MenuCategoriesComponent } from './components/menu-categories/menu-categories.component';
import { MenuCategoryComponent } from './components/menu-category/menu-category.component';
import { FoodCardComponent } from './components/menu-food-card/food-card.component';
import { DrinkCardComponent } from './components/menu-drink-card/drink-card.component';
import { MenuCategoryPremiereComponent } from './components/menu-category-premiere/menu-category-premiere.component';
import { MenuNavigationComponent } from './components/menu-navigation/menu-navigation.component';
import { RestaurantTitleComponent } from './components/restaurant-title/restaurant-title.component';
// import { EventsSliderComponent } from './components/events-slider/events-slider.component';

import { DataService } from './services/data.service';
import { IntToColorPipe } from './pipes/int-to-color.pipe';
import { SortFoodsPipe } from './pipes/sort-foods.pipe';
import { MenuHeaderComponent } from './components/menu-header/menu-header.component';
import { MenuBasketComponent } from './components/menu-basket/menu-basket.component';
import { MenuBasketDialogComponent } from './components/menu-basket-dialog/menu-basket-dialog.component';
import { MenuFoodDialogComponent } from './components/menu-food-dialog/menu-food-dialog.component';

import { ModificatorsSliderComponent } from './components/menu-modificators-slider/modificators-slider.component';
import { MenuDialogVariantsComponent } from './components/menu-dialog-variants/menu-dialog-variants.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuHomepageComponent,
    LandingpageComponent,
    FoodCardComponent,
    DrinkCardComponent,
    MenuCategoryPremiereComponent,
    MenuCategoryComponent,
    MenuCategoriesComponent,
    MenuBasketComponent,
    MenuBasketDialogComponent,
    MenuFoodDialogComponent,
    MenuNavigationComponent,
    // EventsSliderComponent,
    RestaurantTitleComponent,
    FilterDialogComponent,
    IntToColorPipe,
    SortFoodsPipe,
    MenuHeaderComponent,
    ModificatorsSliderComponent,
    MenuDialogVariantsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSlideToggleModule,
    MatCardModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    FormsModule,
    provideAuth(() => {
      const auth = getAuth();
      if (auth.app.options.projectId!.indexOf('demo') === 0)
        connectAuthEmulator(auth, 'http://127.0.0.1:9099');

      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();

      if (firestore.app.options.projectId!.indexOf('demo') === 0)
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);

      return firestore;
    }),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    
  ],
  providers: [
    DataService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
