import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { Category } from 'src/interfaces/category.model';
import { ScrollService } from 'src/app/services/scroll.service';
import { NavigationCategoryService } from 'src/app/services/navigation-category.service';

@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.css']
})
export class MenuNavigationComponent implements OnInit, OnDestroy {
  constructor(
    private navigationCategoryService: NavigationCategoryService,
    private scrollService: ScrollService
  ) {
    this.categoriesSubscription = new Subscription();
    this.scrollSubscription = new Subscription();
  }

  sortedCategories: Category[] = [];
  currentCategoryId: string | undefined = undefined;

  private categoriesSubscription: Subscription;
  private scrollSubscription: Subscription;

  private observerTop: IntersectionObserver = new IntersectionObserver(() => {});
  private observerBottom: IntersectionObserver = new IntersectionObserver(() => {});
  isScrollEnabled: boolean = true;

  @ViewChild('categoriesNavigation') categoriesNavigation!: ElementRef;

  ngOnInit(): void {
    this.categoriesSubscription = this.navigationCategoryService.categories$.subscribe(categories => {
      this.sortedCategories = categories.sort((a, b) => a.index - b.index);

      // Первая категория - текущая
      if (this.sortedCategories && this.sortedCategories.length > 0) {
        this.currentCategoryId = this.sortedCategories[0].id;
        this.updateActiveCategory();
      }
      
      // Начинаем наблюдение за категориями после их получения
      console.log('MenuNavigationComponent | ngOnInit() | sortedCategories: ', this.sortedCategories.map(c => c.title + ' (' + c.index + ')').join(', '));
      this.observeCategories();
    });

    this.scrollSubscription = this.scrollService.scrollEnabled$.subscribe(enabled => {
      // console.log('MenuNavigationComponent | ngOnInit() | scrollEnabled: ', enabled);
      this.isScrollEnabled = enabled;
    });

    // Инициализация состояния скролла
    this.isScrollEnabled = this.scrollService.isScrollEnabled();
  }

  ngOnDestroy(): void {
    this.categoriesSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
    // Отключаем наблюдателей
    this.observerTop.disconnect();
    this.observerBottom.disconnect();
  }

  private createObserver(): void {
    // Очистка существующих наблюдателей верхнего
    if (this.observerTop) {
      this.observerTop.disconnect();
    }

    // Очистка существующих наблюдателей нижнего
    if (this.observerBottom) {
      this.observerBottom.disconnect();
    }

    this.observerTop = new IntersectionObserver(
      entries => {
        if (!this.isScrollEnabled) {
          return;
        }
        entries.forEach(entry => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const categoryId = entry.target.getAttribute('id');
            if (categoryId) {
              const category = this.sortedCategories.find(category => category.id === categoryId);
              console.log('MenuNavigationComponent | observerTop() | category: ', category?.title ?? 'unknown');
              this.currentCategoryId = categoryId;
              this.horizonallScrollToCategory(categoryId);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: `0px 0px -${window.innerHeight * 0.7}px 0px`,
        threshold: 0.1
      }
    );

    this.observerBottom = new IntersectionObserver(
      entries => {
        if (!this.isScrollEnabled) {
          return;
        }

        entries.forEach(entry => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const categoryId = entry.target.getAttribute('id');
            if (categoryId) {
              const currentCategory = this.sortedCategories.find(category => category.id === categoryId);
              // console.log('MenuNavigationComponent | observerBottom() | category: ', currentCategory?.title ?? 'unknown');
              const currentIndex = this.sortedCategories.findIndex(category => category.id === categoryId);
              const previousCategory = currentIndex > 0 ? this.sortedCategories[currentIndex - 1] : null;
              if (previousCategory && previousCategory.id !== this.currentCategoryId) {
                this.currentCategoryId = previousCategory.id;
                this.horizonallScrollToCategory(previousCategory.id);
              }
            }
          }
        });
      },
      {
        root: null,
        rootMargin: `-${window.innerHeight * 0.6}px 0px 0px 0px`, // отрицательный отступ сверху на 70% высоты окна
        threshold: 0.1 // порог пересечения
      }
    );
  }

  private observeCategories(): void {
    if (!this.isScrollEnabled) {
      return;
    }

    // Создаем или очищаем существующих наблюдателей
    this.createObserver();
    this.sortedCategories.forEach(category => {
      const element = document.getElementById(category.id);
      if (element) {
        this.observerTop.observe(element);
        this.observerBottom.observe(element);
      }
    });
  }

  public scrollToCategory(categoryId: string): void {
    if (!this.isScrollEnabled) {
      return;
    }

    const element = document.getElementById(categoryId);
    if (element) {
      let headerOffset = 66;
      console.log('headerOffset', headerOffset);
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition,
      });
      this.currentCategoryId = categoryId;
      this.updateActiveCategory();
      this.horizonallScrollToCategory(categoryId);
    }
  }

  private horizonallScrollToCategory(categoryId: string): void {
    const navigationList = this.categoriesNavigation.nativeElement;
    const categoryElement = navigationList.querySelector(`[data-id="${categoryId}"]`);

    if (categoryElement) {
      const rect = categoryElement.getBoundingClientRect();
      const navigationListRect = navigationList.getBoundingClientRect();

      // Вычисляем смещение
      const offset = rect.left - navigationListRect.left + navigationList.scrollLeft;
      const elementWidth = rect.width;
      const viewportWidth = window.innerWidth;

      if (rect.left < 0 || rect.right > viewportWidth) {
        // categoryElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
         // Прокручиваем элемент к центру с учетом смещения
        const scrollPosition = offset - (viewportWidth / 2) + (elementWidth / 2);
        navigationList.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (!this.isScrollEnabled) {
      return;
    }

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight || document.documentElement.clientHeight;

    // Проверяем, находится ли пользователь внизу страницы
    if (scrollTop + clientHeight >= scrollHeight) {
      // Устанавливаем последнюю категорию как активную
      if (this.sortedCategories.length > 0) {
        const lastCategoryId = this.sortedCategories[this.sortedCategories.length - 1].id;
        this.currentCategoryId = lastCategoryId;
        this.horizonallScrollToCategory(lastCategoryId);
      }
    }
  }

  updateActiveCategory(): void {
    const navigationList = this.categoriesNavigation.nativeElement;
    const categories = navigationList.querySelectorAll('.category-item');
    categories.forEach((category: HTMLElement) => {
      const categoryId = category.getAttribute('data-id');
      if (categoryId === this.currentCategoryId) {
        category.classList.add('active');
      } else {
        category.classList.remove('active');
      }
    });
  }

  private isCategoryTitleInView(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return rect.top < window.innerHeight / 2 && rect.bottom > window.innerHeight / 2;
  }
  
}