import { Injectable } from "@angular/core";
import {
    Firestore,
    collection,
    collectionData,
    query,
    where,
} from "@angular/fire/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { Category } from "src/interfaces/category.model";     
import { Restaurant } from "src/interfaces/restaurant.model";
import { DataService } from "./data.service";

@Injectable({
    providedIn: 'root'
  })

export class CategoryService {
    private categoriesSubject: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
    public categories$: Observable<Category[]> = this.categoriesSubject.asObservable();
    restaurant: Restaurant | null;
    store: Firestore;

    constructor(store: Firestore, private dataService: DataService) {
        this.store = store;
        this.restaurant = this.dataService.restaurant;
    }

    categories(): Observable<Category[]> {
        const categoriesCollectionRef = collection(this.store, 'categories');
        const filteredCategoiesQuery = query(categoriesCollectionRef, 
            // where('account', '==', this.restaurant?.account),
            where('active', '==', true),
            where('id', 'in', this.restaurant?.categories ?? ['']),
        );
        return collectionData(filteredCategoiesQuery,
            { idField: 'id' }) as Observable<Category[]>;
    }

    // Пример метода для обновления списка категорий
    updateCategories(newCategories: Category[]): void {
        console.log('CategoryService | updateCategories() ', newCategories.forEach(c => c.title));
        this.categoriesSubject.next(newCategories);
    }

    // Метод для добавления одной категории в список категорий
    addCategory(newCategory: Category): void {
        const currentCategories = this.categoriesSubject.getValue();
        if (!currentCategories.map(c => c.id).includes(newCategory.id)) {
            const updatedCategories = [...currentCategories, newCategory];
            this.categoriesSubject.next(updatedCategories);
        } 
    }
}