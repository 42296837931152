
import { Component, Input } from '@angular/core';
import { Drink } from 'src/interfaces/drink.model';
import { Labels, Units } from 'src/interfaces/enums.model';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Restaurant } from 'src/interfaces/restaurant.model';
import { Locale } from 'src/interfaces/locale.model';

@Component({
  selector: 'app-drink-card',
  templateUrl: './drink-card.component.html',
  styleUrls: ['./drink-card.component.css'],
})

export class DrinkCardComponent {
  constructor(private dataService: DataService, private router: Router,) {
    this.restaurant = this.dataService.restaurant;
    this.locale = this.dataService.locale;
  }
  restaurant: Restaurant | null = null;
  locale: Locale | null = null;
  unit: string | null = null;

  @Input() drink: Drink | null = null;
  
  Labels = Labels;

  onCardClicked = () => {
    if (this.drink) {
      this.router.navigate(['/drink', this.drink.id]);
    }
  }
  ngOnInit() {
    if (this.drink) {
      // console.log('DrinkCardComponent | ngOnInit() | food:', this.drink.title, 'labels:', this.drink.labels.length ? this.drink.labels.map(x => Labels[x]).join(', ') : 'empty');
      if (this.locale && this.drink?.unit != Units.unknown) {
        this.unit = this.locale.units.get(this.drink.unit) ?? null;
        // console.log('DrinkCardComponent | ngOnInit() | unit:', this.drink.unit, ':', this.unit );
      }
    }
  }
}
