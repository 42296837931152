<div class="drink-card-container" *ngIf="drink" (click)="stoplist ? null : onCardClicked(); $event.stopPropagation()">
  <mat-card class="drink-card">
    <div class="image-container">
      <img class="drink-card-image" mat-card-image [class.stop-list]="stoplist"
        [src]="getImageSrc(drink.image)"
        loading="eager"
        decoding="async"
        alt="Photo of the drink"
      />
      <!-- Продуктовые баннеры -->
      <div class="banners" [class.stop-list]="stoplist">
        <span class="hit-banner" *ngIf="drink.labels.includes(Labels.hit)">хит</span>
        <span class="spicy-banner" *ngIf="drink.labels.includes(Labels.spicy)">острое</span>
        <span class="premiere-banner" *ngIf="drink.labels.includes(Labels.premiere)">новинка</span>
        <span class="veget-banner" *ngIf="drink.labels.includes(Labels.vegan)">вегетарианское</span>
        <span class="nonalcohol-banner" *ngIf="drink.labels.includes(Labels.nonalcohol)">безалкогольное</span>
        <span class="alcohol-banner" *ngIf="drink.labels.includes(Labels.alcohol)">алкоголь</span>
      </div>

      <!-- Служебные баннеры -->
      <div class="info-banner">
        <span class="stoplist-banner" *ngIf="stoplist">закончилось</span>
      </div>
    </div>

    <mat-card-content class="drink-info" [class.stop-list]="stoplist">
      <div class="drink-info__title">
        <div class="drink-title" [innerHTML]="drink.title"></div>
      </div>

      <mat-card-title class="drink-info__price" [class.stop-list]="stoplist">
        {{ variability ? 'от ' : '' }}{{ price }} {{ locale?.currency }}  <span class="drink-size" [class.stop-list]="stoplist" *ngIf="drink.size">{{ drink.size }} {{ locale?.units?.get(drink.unit) }}</span>
      </mat-card-title>

      <div class="drink-info__count" *ngIf="basketDrink">
        <img src="assets/basket.svg" alt="basket" class="drink-basket-icon" />
        <span class="drink-count">{{ basketDrink.count }}</span>
      </div>
    </mat-card-content>
  </mat-card>
</div>