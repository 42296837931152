<div class="category">
  <div [id]="category.id"></div>
  <div *ngIf="filteredFoods.length || filteredDrinks.length">
    <!-- category title -->
    <div class="category-title">
      <h1>Новинки</h1>
    </div>
    <div class="product-grid" id="inner-grid">
      <!-- FOODS -->
      <div class="product-grid-cell" *ngFor="let food of filteredFoods">
        <app-food-card [food]="food"></app-food-card>
      </div>
      <!-- DRINKS -->
      <div class="product-grid-cell" *ngFor="let drink of filteredDrinks">
        <app-drink-card [drink]="drink"></app-drink-card>
      </div>
    </div>
  </div>
</div>
