import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import { BasketService } from 'src/app/services/basket.service';
import { DataService } from 'src/app/services/data.service';
import { Drink } from 'src/interfaces/drink.model';
import { Units } from 'src/interfaces/enums.model';
import { Locale } from 'src/interfaces/locale.model';
import { Modificators } from 'src/interfaces/modificators.model';
import { v4 as uuidv4 } from 'uuid';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-menu-drink-dialog',
  templateUrl: './menu-drink-dialog.component.html',
  styleUrls: ['./menu-drink-dialog.component.css'],
  animations: [
    trigger('slideToggle', [
      state('void', style({ height: '0px', opacity: 0 })),
      state('*', style({ height: '*', opacity: 1 })),
      transition('void <=> *', animate('200ms ease-in-out'))
    ])
  ]
})
export class MenuDrinkDialogComponent implements OnInit, AfterViewInit{

  constructor(
    private dialogRef: MatDialogRef<MenuDrinkDialogComponent>,
    private dataService: DataService,
    private basketService: BasketService,
    @Inject(MAT_DIALOG_DATA) public data: Drink // Принимаем объект Drink
  ) {
    this.locale = this.dataService.locale;
    this.drink = this.data;
    this.price = this.drink.price.toLocaleString('ru-RU');
    this.totalPrice = ((this.drink.count || 1) * this.drink.totalPrice).toLocaleString('ru-RU');
    this.size = this.drink.size;
    this.kkal = this.drink.kkal;
    this.original = cloneDeep(this.drink);
    this.isInfoVisible = false;
  }

  @ViewChild('scrollContent') scrollContent!: ElementRef;
  @ViewChild('titleOriginal') titleOriginal!: ElementRef;
  titleOffset: number = 12;
  
  // Свайп
  startY: number = 0;
  currentY: number = 0;
  isSwiping: boolean = false;
  initialHeight: number = 100; // Процент высоты экрана
  minHeight: number = 20; // Процент уменьшения, при котором окно закроется

  drink: Drink;
  original: Drink;
  locale: Locale | null;
  isBasket: boolean = false;
  canUpdate: boolean = false;

  // Текстовое выражение для цены
  price: string;
  // Текстовое выражение для суммы
  totalPrice: string;
  // Размер
  size: number | null;
  // Калории
  kkal: number | null;

  // Модификаторы
  modificators: Modificators | undefined = undefined;

  isInfoVisible: boolean;

  ngOnInit(): void {
    if (this.drink.basketId) {
      this.isBasket = true;
      this.canUpdate = false;
    } else {
      this.drink.count = this.drink.count || 1;
      this.original.count = this.drink.count || 1;
    }

    if (this.drink.variability && this.drink.variants.length > this.drink.choise) {
      console.log('MenuDrinkDialog | ngOnInit() | choise:', this.drink.choise, ' | count:', this.drink.count, ' | price:', this.drink.totalPrice);
      // Цена напитка
      this.price = (this.drink.variants[this.drink.choise].price ?? this.drink.price).toLocaleString('ru-RU');
      // Объем напитка
      this.size = (this.drink.variants[this.drink.choise].size ?? this.drink.size);
    } else {
      this.price = this.drink.price.toLocaleString('ru-RU');
      this.size = this.drink.size;
    }

    // Расчитываем калории на весь напиток, если энергетическая ценность на 100 г/мл
    if (this.drink.energy && this.drink.kkal && (this.drink.unit === Units.weight || this.drink.unit === Units.volume) && this.size) {
      this.kkal = this.size / 100 * this.drink.kkal;
    }

    // Загружаем модификаторы
    const modificators = typeof this.drink.modificators === 'object' ? this.drink.modificators : undefined;
    console.log('MenuDrinkDialog | ngOnInit() | modificators:', modificators);
    if (modificators) {
      this.modificators = modificators;
    }
  }

  ngAfterViewInit() {
    this.scrollContent.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    this.titleOffset = this.titleOriginal.nativeElement.offsetTop - 4;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
    this.isSwiping = true;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (!this.isSwiping) return;

    this.currentY = event.touches[0].clientY;
    const swipeDistance = this.currentY - this.startY;

    const content = document.querySelector('.drink-dialog-content')!;
    const scrollPosition = content.scrollTop;

    // Проверяем, находится ли контент внизу
    const isContentAtTop = scrollPosition == 0;

    if (swipeDistance > 0 && isContentAtTop) { // Свайп вниз и контент прокручен до конца
      event.preventDefault(); // Предотвращаем стандартное поведение, если происходит свайп вниз и контент внизу
      const newHeight = this.initialHeight - (swipeDistance / window.innerHeight * 100);
      this.updateDialogHeight(newHeight);

      // Предотвращаем обновление страницы
      if (newHeight < this.initialHeight) {
        event.preventDefault();
      }
    }
  }

  onScroll(event: any) {
    const scrollOffset = this.scrollContent.nativeElement.scrollTop;
    
    const title = document.querySelector('.drink-dialog-title__header')!
    // console.log('MenuDrinkDialog | onScroll() | scrollOffset:', scrollOffset, ' | titleOffset:', this.titleOffset);
    if (scrollOffset > this.titleOffset) {
      title.classList.add('show');
    } else {
      title.classList.remove('show');
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.isSwiping = false;
    const swipeDistance = this.currentY - this.startY;
    const swipePercentage = swipeDistance / window.innerHeight * 100;

    if (swipePercentage > this.minHeight) {
      this.close();
    } else {
      this.resetDialogHeight();
    }
  }

  updateDialogHeight(height: number) {
    const dialog = document.querySelector('.drink-dialog-container') as HTMLElement;
    if (dialog) {
      dialog.style.height = `${Math.max(height, 0)}vh`;
    }
  }

  resetDialogHeight() {
    const dialog = document.querySelector('.drink-dialog-container') as HTMLElement;
    if (dialog) {
      dialog.style.height = `${this.initialHeight}vh`;
    }
  }

  onModificatorsChange() {
    console.log('MenuDrinkDialog | onModificatorsChange()');
    this.updateTolalPrice();
    this.totalPrice = (this.drink.count * this.drink.totalPrice).toLocaleString('ru-RU');
    this.checkCanUpdateBasket();
  }

  onVariantsChange() {
    console.log('MenuDrinkDialog | onVariantsChange()')
    this.updateTolalPrice();
    this.totalPrice = (this.drink.count * this.drink.totalPrice).toLocaleString('ru-RU');

    // Цена напитка
    this.price = (this.drink.variants[this.drink.choise].price ?? this.drink.price).toLocaleString('ru-RU');

    // Объем
    this.size = (this.drink.variants[this.drink.choise].size ?? this.drink.size);

    // Расчитываем калории на весь напиток, если энергетическая ценность на 100 г/мл
    if (this.drink.energy && this.drink.kkal && (this.drink.unit === Units.weight || this.drink.unit === Units.volume) && this.size) {
      this.kkal = this.size / 100 * this.drink.kkal;
    }
    
    // Проверка корзины, можно обновить или добавить
    this.checkCanUpdateBasket();
  }

  increment(): void {
    this.drink.count++;
    this.totalPrice = (this.drink.count * this.drink.totalPrice).toLocaleString('ru-RU');
    this.checkCanUpdateBasket();
  }

  decrement(): void {
    if (this.drink.count > 1) {
      this.drink.count--;
      this.totalPrice = (this.drink.count * this.drink.totalPrice).toLocaleString('ru-RU');
      this.checkCanUpdateBasket();
    }
  }

  // Отписка от событий при уничтожении компонента
  ngOnDestroy() {
    this.scrollContent.nativeElement.removeEventListener('scroll', this.onScroll.bind(this));
  }

  buttonPushRemoveDrinkBasket(): void {
    if (!this.isBasket) {

      // Добавляем блюдо в корзину
      console.log('MenuDrinkDialog | buttonPushRemoveDrinkBasket() | pushDrinkToBasket');
      this.drink.basketId = uuidv4();
      this.basketService.pushDrinkToBasket(this.drink);
    } else {
      if (this.canUpdate) {

        // Обновляем блюдо в корзине
        console.log('MenuDrinkDialog | buttonPushRemoveDrinkBasket() | pushDrinkToBasket');
        this.basketService.updateDrinkInBasket(this.drink);
      } else {

        // Удаляем блюдо из корзины
        console.log('MenuDrinkDialog | buttonPushRemoveDrinkBasket() | removeDrinkDromBasket');
        this.basketService.removeDrinkFromBasket(this.drink);
      }
    } 
    this.close();
  }

  close(): void {
    const dialog = document.querySelector('.drink-dialog-container');
    if (dialog) {
      dialog.classList.add('slide-down-animation');
      setTimeout(() => this.dialogRef.close(), 250); // Задержка для анимации
    }
  }

  updateTolalPrice(): void {
    let totalPrice = 0;
    // Цена за вариант (если есть)
    if (this.drink.variability && this.drink.variants.length) {
      totalPrice = this.drink.variants[this.drink.choise].price ?? this.drink.price;
    } else {
      // Цена за единицу
      totalPrice = this.drink.price;
    }

    const modificators = typeof this.drink.modificators === 'object' ? this.drink.modificators : null;
    console.log('MenuDrinkDialog | updateTolalPrice() | modificators:', modificators);
    modificators?.items.forEach(item => {
      if (item.count != null && item.count > 0) {
        totalPrice += item.price * item.count
      }
    });

    this.drink.totalPrice = totalPrice;
    console.log('Drink | updateTolal() | count:', this.drink.count, ' | price:', this.drink.totalPrice);
  }

  private checkCanUpdateBasket(): void {
    if (this.isBasket) {

      // Изменился вариант блюда
      if (this.drink.choise != this.original.choise) {
        this.canUpdate = true;
        console.log('MenuDrinkDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);
        return
      }

      // Изменилось количество
      if (this.drink.count != this.original.count) {
        this.canUpdate = true;
        console.log('MenuDrinkDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);
        return
      }

      // Проверяем, есть ли изменения в модификаторах
      const current = typeof this.drink.modificators === 'object' ? this.drink.modificators : undefined;

      // Модификаторы есть
      if (current && current.items.length > 0) {
        // console.log('MenuDrinkDialog | checkCanUpdateBasket() | Modificator groups:', groups.length);
        
        const modificators = typeof this.original.modificators === 'object' ? this.original.modificators : undefined;
        if (modificators && modificators.items.length > 0) {
          // console.log('MenuDrinkDialog | checkCanUpdateBasket() | modificators:', modificators.items.length, modificatorsCurrent.items.length,);
          
          // Проход по элементам
          for (let i = 0; i < modificators.items.length; i++) {
            
            // Проверяем количество
            if (typeof modificators.items[i] != 'object' || typeof current.items[i] != 'object') {
              this.canUpdate = false;
              return
            }
            if (modificators.items[i].count != current.items[i].count) {
              this.canUpdate = true;
              console.log('MenuDrinkDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);
              return
            }
          }
        }
      }
      
      this.canUpdate = false;
      console.log('MenuDrinkDialog | checkCanUpdateBasket() | canUpdate:', this.canUpdate);

    } else {
      this.canUpdate = false;
    }
  }

  selectVariants(index: number) {
    console.log('number: ' + index);
    // this.selectedIndex = index;
  }

  toggleInfo() {
    this.isInfoVisible = !this.isInfoVisible;
  }

  getImageSrc(image?: string): string {
    return image && image.trim() ? image : 'assets/no_photo_ru.png';
  }

  get roundedKkal(): string {
    return this.kkal ? this.kkal.toLocaleString('ru-RU', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '';
  }

  get localSize(): string {
    return this.size ? this.size.toLocaleString('ru-RU', { useGrouping: true }) : '';
  }
}

