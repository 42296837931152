
import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, Input , ChangeDetectorRef} from '@angular/core';
import { Restaurant } from '../../../interfaces/restaurant.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-restaurant-title',
  templateUrl: './restaurant-title.component.html',
  styleUrls: ['./restaurant-title.component.css'],
})
export class RestaurantTitleComponent implements AfterViewInit {
  constructor(private dataService: DataService, private cdr: ChangeDetectorRef,) {}

  restaurant: Restaurant | null = null;

  ngAfterViewInit() {
    // Получаем изначальную ширину блока после загрузки компонента
    let width = window.innerWidth;
     this.restaurant =this.dataService.restaurant;
    this.cdr.detectChanges(); 
    // console.log('RestaurantTitleComponent | ngAfterViewInit()');
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    console.log('RestaurantLogoComponent | onResize() | width:', width);
  }

}
