<div
  class="drink-card-container" *ngIf="drink" (click)="onCardClicked(); $event.stopPropagation()">
  <mat-card class="drink-card">
    <div class="image-container">
      <img
        mat-card-image
        [src]="drink.image ? drink.image : 'assets/no_photo_ru.png'"
        alt="Photo of the drink"
        id="mat-card-image"
      />
      <div class="banners">
        <span class="hit-banner" *ngIf="drink.labels.includes(Labels.hit)">хит</span>
        <span class="spicy-banner" *ngIf="drink.labels.includes(Labels.spicy)">острое</span>
        <span class="premiere-banner" *ngIf="drink.labels.includes(Labels.premiere)">новинка</span>
        <span class="veget-banner" *ngIf="drink.labels.includes(Labels.vegan)">вегетарианское</span>
        <span class="nonalcohol-banner" *ngIf="drink.labels.includes(Labels.nonalcohol)">безалкогольное</span>
        <span class="alcohol-banner" *ngIf="drink.labels.includes(Labels.alcohol)">алкоголь</span>
      </div>
    </div>

    <mat-card-content class="drink-info">
      <div class="drink-info__title">
        <div class="drink-title" [innerHTML]="drink.title"></div>
      </div>

      <mat-card-title class="drink-info__price">
        {{ drink.price }}{{ locale?.currency }}  <span class="drink-size" *ngIf="drink.size">{{ drink.size }} {{ unit }}</span>
      </mat-card-title>
    </mat-card-content>
  </mat-card>
</div>