<div class="variants-food-recomendation-container">
    <div class="variants-food-recomendation-background" [ngStyle]="{
            'width': 'calc(' + (100 / (food?.variants?.length || 1)) + '%)',
            'transform': 'translateX(calc(' + (choise * 100) + '%))'
        }"></div>

    <ng-container *ngFor="let item of food?.variants; let i = index">
        <input type="radio" [id]="'variant-recomendation' + i" name="variants-food-recomendation" class="variants-food-recomendation-input" [value]="i"
            [(ngModel)]="choise" (change)="onRecomendationChoiseClick(); $event.stopPropagation()">
        <label [for]="'variant-recomendation' + i" class="variants-food-recomendation-label">{{ item.title }}</label>
    </ng-container>
</div>