<div class="basket-dialog-container">
  <div class="basket-dialog-header">
    <p class="basket-title">Корзина</p>
    <button mat-icon-button class="close-button" (click)="close(); $event.stopPropagation()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="basket-dialog-content" #scrollContent>
    <!-- Ваше содержимое корзины -->
    <div *ngIf="foods && foods.length">
        <app-basket-food-card
         *ngFor="let food of foods; let last = last"
         [food]="food"
         [isLast]="last && drinks != null && drinks.length === 0"
        ></app-basket-food-card>
    </div>
    <div *ngIf="drinks && drinks.length">
      <app-basket-drink-card
       *ngFor="let drink of drinks; let last = last"
       [drink]="drink"
       [isLast]="last"
      ></app-basket-drink-card>
    </div>
    <div class="basket-dialog-footer"></div>
  </div>
</div>

<!-- Test -->
<app-menu-basket-order></app-menu-basket-order>
