import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scrollEnabled = new BehaviorSubject<boolean>(true);
  scrollEnabled$ = this.scrollEnabled.asObservable();

  setScrollEnabled(enabled: boolean): void {
    this.scrollEnabled.next(enabled);
  }

  isScrollEnabled(): boolean {
    return this.scrollEnabled.getValue();
  }
}
