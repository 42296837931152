<div class="variants-dialog-container">
    <div class="variants-dialog-background" [ngStyle]="{
            'width': 'calc(' + (100 / (food?.variants?.length || 1)) + '%)',
            'transform': 'translateX(calc(' + (choise * 100) + '%))'
        }"></div>

    <ng-container *ngFor="let item of food?.variants; let i = index">
        <input type="radio" [id]="'variant-' + i" name="variants-dialog" class="variants-dialog-input" [value]="i"
            [(ngModel)]="choise" (change)="onChoiseClick(); $event.stopPropagation();">
        <label [for]="'variant-' + i" class="variants-dialog-label">{{ item.title }}</label>
    </ng-container>
</div>