
import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { Food } from 'src/interfaces/food.model';
import { Drink } from 'src/interfaces/drink.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-menu-basket',
  templateUrl: './menu-basket.component.html',
  styleUrls: ['./menu-basket.component.css'],
})
export class MenuBasketComponent implements OnInit, OnDestroy{
  constructor(private dataService: DataService, private basketService: BasketService) {
    this.locale = this.dataService.locale;
  }

  foods: Food[] = [];
  foodsCount: number = 0;
  drinks: Drink[] = [];
  drinksCount: number = 0;
  price: string = '';
  locale: any = { currency: '' };
  private basketFoodsSubscription: Subscription = new Subscription();
  private basketDrinksSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.basketFoodsSubscription = this.basketService.foods.subscribe((data: Food[]) => {
      this.foods = data;
      this.updateBasketPrice();
      console.log('MenuBasket | ngOnInit() | foods:', this.foods.map(x => x.title).join(', '));
    });

    this.basketDrinksSubscription = this.basketService.drinks.subscribe((data: Drink[]) => {
      this.drinks = data;
      this.updateBasketPrice();
      console.log('MenuBasket | ngOnInit() | drinks:', this.drinks.map(x => x.title).join(', '));
    });
  }

  ngOnDestroy(): void {
    if (this.basketFoodsSubscription) {
      this.basketFoodsSubscription.unsubscribe();
    }
    if (this.basketDrinksSubscription) {
      this.basketDrinksSubscription.unsubscribe();
    }
  }

  private updateBasketPrice(): void {
    let total = 0;
    this.foodsCount = 0;
    this.drinksCount = 0;
    
    // Проход по каждому блюду
    this.foods.forEach(food => {

      // Считаем стоимость блюда с учетом количества
      this.foodsCount += food.count;
      total += food.totalPrice * food.count;
    });

    this.drinks.forEach(drink => {

      // Считаем стоимость напитка с учетом количества
      this.drinksCount += drink.count;
      total += drink.totalPrice * drink.count;
    });

    // Обновляем стоимость корзины, отделяем тысяи
    this.price = total.toLocaleString('ru-RU'); 
  }
}
