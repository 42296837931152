<!-- <div id="toolbar" [style.opacity]="toolbarOpacity">
</div> -->

<!-- <div id="toolbar-search">
   <div id="search-bar" class="toolbar-center">
      125
   </div>
</div> -->


<div class="grid-categories">
   <!-- Категории с блюдами и напитками -->
   <div *ngIf="sortedCategories.length">
      <div *ngFor="let category of sortedCategories">
         <div [id]="category.id"></div>
         <app-menu-category [category]="category"></app-menu-category>
      </div>
   </div>
</div>

  
