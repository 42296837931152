
import { Component, OnChanges, Input, SimpleChanges , ElementRef, HostListener, ViewChild, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryService } from '../../services/category.service';
import { Category } from 'src/interfaces/category.model';
import { Restaurant } from 'src/interfaces/restaurant.model';

@Component({
  selector: 'app-menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.css'],
})
export class MenuCategoriesComponent implements OnInit{
  cdr: any;
  constructor(private categoryService: CategoryService, private elementRef: ElementRef) {
  }
  // @Input() categories : string[] | null = null;

  @ViewChild('categoriesNavigation') categoriesNavigation!: ElementRef;
  
  sortedCategories: Category[] = [];
  logoHeight: number = 0;
  toolbarOpacity = 0;

  ngOnInit(): void {
    this.categoryService.categories$.subscribe(list => {
      if (list) {
        this.sortedCategories = list.slice().sort((a, b) => a.index - b.index);
      } else {
        this.sortedCategories = [];
      }
    });
  }
}
