<!-- <div class="header" [class.scrolled]="isScrolled"> -->
<!-- <div class="search-container" >
  <input type="text" [placeholder]="searchPlaceholder" class="search-input" />
</div> -->
<div class="parallax-container" *ngIf="restaurant" id="header">
  <div class="header-image" [ngStyle]="{
      'width.px': '100%',
      'height.px': imageHeight,
    }">
  </div>
  <div class="parallax" *ngIf="restaurant && isParralaxVisible" [ngStyle]="{
    'background-image': 'url(' + restaurant.image + ')',
    'width.px': '100%',
    'height.px': imageHeight,
    'transform': 'translateY(' + parallaxOffset + 'px)',
    'opacity': opacity,
  }"></div>
  <div class="header-logo">
    <img [src]="restaurant.logo" alt="Logo" class="logo" [ngStyle]="{
      'height.px': logoSize,
      'width.px': logoSize,
      'opacity': opacity,
    }"/>
  </div>
</div>
  <!-- <div class="content">
    <div *ngFor="let item of items; let i = index">
      <p>{{ item }}</p>
    </div>
  </div> -->