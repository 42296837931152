import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Locale } from 'src/interfaces/locale.model';
import { Modificator, Modificators } from 'src/interfaces/modificators.model';

@Component({
  selector: 'app-menu-modificators-grid',
  templateUrl: './menu-modificators-grid.component.html',
  styleUrls: ['./menu-modificators-grid.component.css']
})
export class MenuModificatorsGridComponent {
  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef, 
  ) {
    this.locale = this.dataService.locale;
    
    // Получаем ширину экрана
    this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this.vw = Math.min(this.vw, 480);
    this.width = 105;
    this.height = 140;
  }

  @Input() modificators : Modificators | null = null;
  @Output() modificatorsChange = new EventEmitter(); // Output property

  locale: Locale | null = null;
  vw: number;
  width: number;
  height: number;

  ngAfterViewInit() {
    this.width = (Math.min(this.vw || 480) - 60) / 3;
    this.height = this.width * 1.33;
    // console.log('ModificatorsGridComponent | ngAfterViewInit() | vw:', this.vw, ' | slidesPerView:', this.slidesPerView);
    this.cdr.detectChanges();
  }

  onModificatorClick(modificator: Modificator) {
    console.log('ModificatorsGrid | onModificatorClick() | modificator: ', modificator.title, ' | count:', modificator.count);
    if (modificator.count == 0) {
      this.onModificatorIncrement(modificator);
    } else if (modificator.maximum == 1) {
      this.onModificatorDecrement(modificator);
    }
  }

  onModificatorIncrement(modificator: Modificator) {
    if (modificator.count < modificator.maximum) {
      modificator.count++;
    }
    this.emitChanges(); // Emit changes
    console.log('ModificatorsGrid | onModificatorIncrement() | modificator: ', modificator.title, ' | count:', modificator.count);
  }
  
  onModificatorDecrement(modificator: Modificator) {
    if (modificator.count > 0) modificator.count--;
    this.emitChanges(); // Emit changes
    console.log('ModificatorsGrid | onModificatorDecrement() | modificator: ', modificator.title, ' | count:', modificator.count);
  }
  
  private emitChanges() {
    if (this.modificators) {
      this.modificatorsChange.emit(); // Emit updated modificators
    }
  }

  showPrice(modificator: Modificator): boolean {
    return modificator.maximum == 1 || modificator.count == 0;
  }

  showCounter(modificator: Modificator): boolean {
    return modificator.maximum != 1 && modificator.count > 0;
  }
}
