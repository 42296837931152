import { Injectable } from "@angular/core";
import {
    Firestore,
    collection,
    collectionData,
    query,
    where,
} from "@angular/fire/firestore";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { Category } from "src/interfaces/category.model";     
import { RestaurantsService } from "./restaurant.service";

@Injectable({
    providedIn: 'root'
  })

export class CategoryService {
    private categoriesSubject: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
    public categories$: Observable<Category[]> = this.categoriesSubject.asObservable();

    constructor(
        private store: Firestore, 
        private restaurantsService: RestaurantsService
    ) {
        this.store = store;
        
        // Подписка на ресторан и автоматическое обновление категорий
        this.restaurantsService.restaurant$.pipe(
            switchMap((restaurant) => {
                return this.fetchCategories(restaurant?.categories ?? ['']);
            })
        ).subscribe(categories => {
            this.categoriesSubject.next(categories);
        });
    }

    private fetchCategories(categoryIds: string[]): Observable<Category[]> {
        if (!categoryIds?.length) return new BehaviorSubject<Category[]>([]).asObservable();
        
        const categoriesCollectionRef = collection(this.store, 'categories');
        const filteredCategoriesQuery = query(categoriesCollectionRef, 
            where('active', '==', true),
            where('id', 'in', categoryIds.length ? categoryIds : ['placeholder']) // Предотвращаем ошибку Firestore
        );

        return collectionData(filteredCategoriesQuery, { idField: 'id' }) as Observable<Category[]>;
    }

    // Пример метода для обновления списка категорий
    updateCategories(newCategories: Category[]): void {
        console.log('CategoryService | updateCategories() ', newCategories.forEach(c => c.title));
        this.categoriesSubject.next(newCategories);
    }

    // Метод для добавления одной категории в список категорий
    addCategory(newCategory: Category): void {
        const currentCategories = this.categoriesSubject.getValue();
        if (!currentCategories.map(c => c.id).includes(newCategory.id)) {
            const updatedCategories = [...currentCategories, newCategory];
            this.categoriesSubject.next(updatedCategories);
        } 
    }

    // removeCategory(categoryId: string): void {
    //     const currentCategories = this.categoriesSubject.getValue();
    //     const updatedCategories = currentCategories.filter(c => c.id !== categoryId);
    //     this.categoriesSubject.next(updatedCategories);
    // }

    removeCategory(categoryId: string): void {
        const currentCategories = this.categoriesSubject.getValue();
        const updatedCategories = currentCategories.map(c =>
            c.id === categoryId ? { ...c, active: false } : c
        );

        this.categoriesSubject.next(updatedCategories);
        console.log(`Категория помечена как неактивная: ${categoryId}`);
    }

    categoryExists(categoryId: string): boolean {
        const currentCategories = this.categoriesSubject.getValue();
        return currentCategories.map(c => c.id).includes(categoryId);
    }
}