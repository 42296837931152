import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intToColor'
})
export class IntToColorPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '#000'; // обработка пустого или недопустимого значения, возвращаем черный цвет
    }

    // Преобразование числа в шестнадцатеричную строку и добавление префикса #
    const hexColor = '#' + value.toString(16);

    return hexColor.substring(2);
  }
}
