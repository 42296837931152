
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { Drink } from 'src/interfaces/drink.model';
import { Labels, Units } from 'src/interfaces/enums.model';
import { DataService } from 'src/app/services/data.service';
import { Restaurant } from 'src/interfaces/restaurant.model';
import { Locale } from 'src/interfaces/locale.model';
import { BasketService } from 'src/app/services/basket.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuDrinkDialogComponent } from '../menu-drink-dialog/menu-drink-dialog.component';
import { ScrollService } from 'src/app/services/scroll.service';

import { cloneDeep, debounce } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-drink-card',
  templateUrl: './drink-card.component.html',
  styleUrls: ['./drink-card.component.css'],
})

export class DrinkCardComponent implements OnInit{
  constructor(private dataService: DataService, 
    private basketService: BasketService,
    private scrollService: ScrollService,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) {
    this.restaurant = this.dataService.restaurant;
    this.locale = this.dataService.locale;
    this.basketDrinksSubscription = new Subscription();
  }
  restaurant: Restaurant | null = null;
  locale: Locale | null = null;
  
  price: string = '';
  variability: boolean = false;
  basketDrink: Drink | null = null;

  @Input() drink: Drink | null = null;
  @Input() stoplist: boolean = false;

  Labels = Labels;

  private basketDrinksSubscription: Subscription;

  ngOnInit(): void {
    if (this.drink) {
      this.price = this.drink.price.toLocaleString('ru-RU');
      this.variability = this.drink?.variants?.some(v => v.price !== null);

      // Подписка на изменения корзины
      this.basketDrinksSubscription = this.basketService.drinks.subscribe((data: Drink[]) => {
        let basketDrinks = data;
        const existingDrink = basketDrinks.find(f => f.id === this.drink?.id);
        
        if (existingDrink) {
          this.basketDrink = existingDrink;
        } else {
          this.basketDrink = null;
        }
      });
    }
  }

  onCardClicked(): void {
    if (this.stoplist || !this.drink) {
      return;
    }

    // Затемняем строку состояния перед открытием модального окна
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#a6a6ae');

    this.scrollService.setScrollEnabled(false);
    const dialogRef = this.dialog.open(MenuDrinkDialogComponent, {
      panelClass: 'custom-dialog-container', // Дополнительный класс для кастомизации
      position: { bottom: '0' },
      width: '100%',
      maxWidth: '100%',
      data: this.basketDrink ? cloneDeep(this.basketDrink) : cloneDeep(this.drink),
    });

    dialogRef.afterClosed().subscribe(() => {
      this.scrollService.setScrollEnabled(true);
      
      // Возвращаем исходный цвет строки состояния
      document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#f5f5ff');
    });
  }

  getImageSrc(image?: string): string {
    return image && image.trim() ? image : 'assets/no_photo_ru.png';
  }

  ngOnDestroy(): void {
    this.basketDrinksSubscription?.unsubscribe();
  }
}
