import { Component, OnInit, inject , ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';
import {
  where,
  QueryConstraint,
  orderBy,
} from '@angular/fire/firestore';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { DEFAULT_SORT_DATA, DialogData } from '../../components/filter-dialog/dialogdata';
import { RestaurantsService } from '../../services/restaurant.service';
import { Restaurant } from 'src/interfaces/restaurant.model';
import { FullscreenService } from 'src/app/services/fullscreen.service';
import { DataService } from 'src/app/services/data.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Locale } from 'src/interfaces/locale.model';
import { Units } from 'src/interfaces/enums.model';
import { StoplistService } from 'src/app/services/stoplist.service';
import { Analytics, logEvent } from '@angular/fire/analytics';

@Component({
  selector: 'app--menu-homepage',
  templateUrl: './menu-homepage.component.html',
  styleUrls: ['./menu-homepage.component.css'],
})

export class MenuHomepageComponent implements OnInit{
  constructor(
    private restaurantsService: RestaurantsService,
    private localeService: LocaleService,
    private stoplistService: StoplistService,
    public dataService: DataService,
    private route: ActivatedRoute, 
    private fullscreenService: FullscreenService, 
    private elementRef: ElementRef,
    private analytics: Analytics
  ) {
    this.subscriptionRestaurant = new Subscription();
  }

  private subscriptionRestaurant: Subscription;
  private restaurantId = '';
  restaurant: Restaurant | null = null;
  tableId : string | null = null;

  // private restaurantsService = inject(RestaurantsService);
  // private localeService = inject(LocaleService);

  ngOnInit(): void {
    // this.fullscreenService.openFullscreen();
    // this.openFullscreen();
    const restaurantId = this.route.snapshot.paramMap.get('restaurantId') as string;
    const tableId = this.route.snapshot.paramMap.get('tableId') as string;
    console.log('MenuHomepageComponent | ngOnInit() | restaurantId:', restaurantId, 'tableId:', tableId ? tableId : 'null');

    // Analytics
    const restaurant = this.route.snapshot.paramMap.get('restaurantId');
    logEvent(this.analytics, 'page_view', {
      page: 'Home',
      restaurant_id: restaurant ? restaurant : 'null',
    });

    switch (restaurantId) {
      case 'pizza':
        this.restaurantId = 'c7ceb501-e407-1f0a-841e-4dde952e8cc7';
        break;
      case 'caffe':
        this.restaurantId = 'b2f65980-e673-1f0a-841e-4dde952e8cc7';
        break;
      case 'restaurant':
        this.restaurantId = '61c93e01-1904-1f11-8bfd-8175a905f1a8';
        break;
      case 'pub':
        this.restaurantId = 'a5ea5e01-4f0c-1f02-9efa-ddddbf483466';
        break;
      case 'china':
        this.restaurantId = 'cfd74501-5869-1f02-b166-a78a10da43d0';
        break;
      case null:
        this.restaurantId = 'a5ea5e01-4f0c-1f02-9efa-ddddbf483466';
        break;
      default:
        this.restaurantId = restaurantId;
        break;
    }
    
    this.restaurantsService.subscribeToRestaurant(this.restaurantId); // Запускаем подписку

    this.subscriptionRestaurant = this.restaurantsService.restaurant$.pipe(
      filter((data): data is Restaurant => data !== null), // Фильтруем null-значения
      tap((data: Restaurant) => {
        this.dataService.restaurant = data;
        this.restaurant = data;
        console.log('MenuHomepageComponent | ngOnInit() | restaurant:', data.title);

        // Загружаем стоп-лист для ресторана
        this.stoplistService.init(this.restaurantId);
      }),
      switchMap((data: Restaurant) => this.localeService.fetch(data.locale)) // Получаем данные локали
    ).subscribe((data: Locale) => {
      console.log('MenuHomepageComponent | ngOnInit() | locale:', data.code, 'currency:', data.currency);
      this.dataService.locale = data;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionRestaurant?.unsubscribe();
  }

  openFullscreen() {
    const elem = this.elementRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
}
