// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // Основной проект (где собирается аналитика)
  firebaseAnalytics: {
    apiKey: "AIzaSyDyXvOFphuujpo-0esK1u9RvmFvjk1kst4",
    authDomain: "menu-edim-app-78993.firebaseapp.com",
    projectId: "menu-edim-app-78993",
    storageBucket: "menu-edim-app-78993.appspot.com",
    messagingSenderId: "151904645359",
    appId: "1:151904645359:web:5639341d23757b00b4ab1a",
    measurementId: "G-DE8MZRKS8H"
  },
  // Проект для работы с данными
  firebaseDatabase: {
    apiKey: 'AIzaSyCmvYfDzRamfM4KZJa7qoqWUDRq6uIkmOE',
    appId: '1:141947618852:web:e27a18597eccb98d3dbab1',
    messagingSenderId: '141947618852',
    projectId: 'edim-app',
    authDomain: 'edim-app.firebaseapp.com',
    storageBucket: 'edim-app.appspot.com',
    measurementId: 'G-S4Z4D82ZEB'
  }
};