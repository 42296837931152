<div class="variants-dialog-container">
    <!-- Наименование блюда -->
    <div class="variants-dialog-banner">{{ food?.variant }}</div>

    <!-- Список вариантов -->
    <div class="variants-items-container">
        <div  *ngFor="let variant of food?.variants">
            <div class="variant-item" (click)="onChoiseClick(variant.index); $event.stopPropagation()">
                <div class="variant-item__title">{{ variant.title }}</div>
                <div class="variant-item__price" *ngIf="variant.price">+ {{ variant.price }} {{locale?.currency}}</div>
                <div class="variant-item__selector"
                    [ngClass]="{'active-class': (food?.choise ?? 0) == variant.index}">
                </div> 
            </div>
        </div>
    </div>
</div>