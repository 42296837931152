
import { Component } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Food } from 'src/interfaces/food.model';
import { Drink } from 'src/interfaces/drink.model';
import { Category } from 'src/interfaces/category.model';
import { map, Subscription, tap } from 'rxjs';
import { StoplistService } from 'src/app/services/stoplist.service';
import { Labels } from 'src/interfaces/enums.model';
import { NavigationCategoryService } from 'src/app/services/navigation-category.service';

@Component({
  selector: 'app-category-premiere',
  templateUrl: './menu-category-premiere.component.html',
  styleUrls: ['./menu-category-premiere.component.css'],
}) 
export class MenuCategoryPremiereComponent {
  constructor(
    private productService: ProductService,
    private navigationCategoryService: NavigationCategoryService,
    private stoplistService: StoplistService
  ) {
    this.foods = [];
    this.drinks = [];
    this.filteredFoods = [];
    this.filteredDrinks = [];
    this.stoplistFoods = [];
    this.stoplistDrinks = [];

    this.subscriptionFoods = new Subscription();
    this.subscriptionDrinks = new Subscription();
    this.subscriptionStoplistFoods = new Subscription();
    this.subscriptionStoplistDrinks = new Subscription();
  }

  private foods: Food[];
  private drinks: Drink[];

  filteredFoods: Food[] = [];
  filteredDrinks: Drink[] = [];
  
  private stoplistFoods: string[];
  private stoplistDrinks: string[];

  category: Category = {
    id: 'id-premiere-category',
    title: 'Новинки',
    active: true,
    index: 0,
  };

  private subscriptionFoods: Subscription;
  private subscriptionDrinks: Subscription;
  private subscriptionStoplistFoods: Subscription;
  private subscriptionStoplistDrinks: Subscription;

  ngOnInit(): void {
    console.log('MenuCategoryPremiere | ngOnInit()');

    // Subscribe to stoplist foods and drinks
    this.subscriptionStoplistFoods = this.stoplistService.foods$.subscribe(stoplistFoods => {
      console.log('MenuCategoryPremiere | ngOnInit() | subscribe() stoplistFoods: ', stoplistFoods.length);
      this.stoplistFoods = stoplistFoods;
      this.filteredFoods = this.foods.filter(food => !this.stoplistFoods.includes(food.id));
      if (this.filteredFoods.length) {
        this.navigationCategoryService.addCategory(this.category);
      } else if (this.filteredDrinks.length === 0) {
        this.navigationCategoryService.removeCategory(this.category.id);
      }
    });
    
    this.subscriptionStoplistDrinks = this.stoplistService.drinks$.subscribe(stoplistDrinks => {
      console.log('MenuCategoryPremiere | ngOnInit() | subscribe() stoplistDrinks: ', stoplistDrinks.length);
      this.stoplistDrinks = stoplistDrinks;
      this.filteredDrinks = this.drinks.filter(drink => !this.stoplistDrinks.includes(drink.id));
      if (this.filteredDrinks.length) {
        this.navigationCategoryService.addCategory(this.category);
      } else if (this.filteredFoods.length === 0) {
        this.navigationCategoryService.removeCategory(this.category.id);
      }
    });
    
    // foods
    this.subscriptionFoods = this.productService.foods$.pipe(
      map(foods => foods.filter(food => food.labels.includes(Labels.premiere))), // Используем includes
      tap(filtered => {
        console.log('MenuCategoryPremiere | ngOnInit() | subscribe() | foods: ', filtered.length);
        if (filtered.length) {
          this.foods = filtered;
          this.filteredFoods = this.foods.filter(food => !this.stoplistFoods.includes(food.id));
          this.navigationCategoryService.addCategory(this.category);
        } else {
          this.foods = [];
          this.filteredFoods = [];
          if (this.filteredDrinks.length === 0) {
            this.navigationCategoryService.removeCategory(this.category.id);
          }
        }
      })
    ).subscribe();

    // drinks
    this.subscriptionDrinks = this.productService.drinks$.pipe(
      map(drinks => drinks.filter(drink => drink.labels.includes(Labels.premiere))), // Используем includes
      tap(filtered => {
        console.log('MenuCategoryPremiere | ngOnInit() | subscribe() | drinks: ', filtered.length);
        if (filtered.length) {
          this.drinks = filtered;
          this.filteredDrinks = this.drinks.filter(drink => !this.stoplistDrinks.includes(drink.id));
          this.navigationCategoryService.addCategory(this.category);
        } else {
          this.drinks = [];
          this.filteredDrinks = [];
          if (this.foods.length === 0) {
            this.navigationCategoryService.removeCategory(this.category.id);
          }
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    // Отписка при уничтожении компонента
    this.subscriptionFoods?.unsubscribe(); 
    this.subscriptionDrinks?.unsubscribe();
    this.subscriptionStoplistFoods?.unsubscribe();
    this.subscriptionStoplistDrinks?.unsubscribe();
  }
}
