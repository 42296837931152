import { Injectable } from "@angular/core";
import {
    Firestore,
    doc,
    docData
} from "@angular/fire/firestore";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { Restaurant } from "src/interfaces/restaurant.model";


@Injectable({
    providedIn: 'root'
  })

export class RestaurantsService {
    private restaurantSubject = new BehaviorSubject<Restaurant | null>(null);
    public restaurant$ = this.restaurantSubject.asObservable();
    
    // store: Firestore;
    // constructor(store: Firestore) {
    //     this.store = store;
    // }
    constructor(private store: Firestore) {}

    // public getRestaurantData(id: string): Observable<Restaurant> {
    //     const docRef = doc(this.store, 'stores', id);
    //     return docData(docRef, { idField: 'id' }) as Observable<Restaurant>

    // }
    public subscribeToRestaurant(id: string): void {
        const docRef = doc(this.store, 'stores', id);
        docData(docRef, { idField: 'id' }).pipe(
            tap(restaurant => this.restaurantSubject.next(restaurant as Restaurant))
        ).subscribe();
    }

    public getRestaurant(): Restaurant | null {
        return this.restaurantSubject.getValue();
    }

}