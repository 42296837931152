import { Injectable } from "@angular/core";
import {
    Firestore,
    doc,
    docData,
} from "@angular/fire/firestore";
import { BehaviorSubject, map, Observable, of, pipe } from "rxjs";
import { DataService } from "./data.service";
import { Modificator, Modificators } from "src/interfaces/modificators.model";

@Injectable({
    providedIn: 'root'
  })

export class ModificatorsService {
    private modificatorsSubject: BehaviorSubject<Modificators[]> = new BehaviorSubject<Modificators[]>([]);
    // public modificators: Observable<Modificators[]> = this.modificatorsSubject.asObservable();

    constructor(private store: Firestore, private dataService: DataService) {}

    public modificators(id: string): Observable<Modificators> {
        const currentModificators = this.modificatorsSubject.getValue();
        const cachedModificator = currentModificators.find(mod => mod.id === id);
        
        if (cachedModificator) {
            // Возвращаем модификатор из кеша, если он уже загружен
            return of(cachedModificator);
        }

        // Если модификатор не найден в кеше, загружаем его из Firebase
        const modificatorsDocRef = doc(this.store, 'modificators', id);
        return (docData(modificatorsDocRef, { idField: 'id' }) as Observable<Modificators>)
            .pipe(
                map((modificators: Modificators) => {
                    modificators.items = modificators.items.map((item: Modificator) => ({
                        ...item,
                        count: item.count ?? 0 // Если count null, присваиваем 0
                    }));

                    // Добавляем полученные модификаторы в состояние
                    this.insert(modificators);
                    return modificators;
                })
            );
    }

    // Метод для добавления группы модификаторов в список
    private insert(modificators: Modificators): void {
        const currentModificators = this.modificatorsSubject.getValue();
        if (!currentModificators.map(c => c.id).includes(modificators.id)) {
            console.log('ModificatorsService | insert() ', modificators.title);
            const updatedModificators = [...currentModificators, modificators];
            this.modificatorsSubject.next(updatedModificators);
        } else {
            console.log('ModificatorsService | insert() | already ', modificators.title);
        }
    }

    // Метод для получения списка модификаторов
    public getModificators(list: string[]): Modificators[] {
        const modificators = this.modificatorsSubject.getValue();
        if (!modificators.length) return [];
        return modificators.slice().filter(m => list.includes(m.id)) ?? [];
    }
}