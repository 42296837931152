import { Injectable } from "@angular/core";
import {
    Firestore,
    collection,
    collectionData,
    query,
    where,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { Food } from "src/interfaces/food.model";
import { Restaurant } from "src/interfaces/restaurant.model";
import { DataService } from "./data.service";
import { Drink } from "src/interfaces/drink.model";
import { Labels, Units } from 'src/interfaces/enums.model';
import { map } from 'rxjs/operators'; 
import { Variant } from "src/interfaces/variant.model";

@Injectable({
    providedIn: 'root'
  })

export class ProductService {
    store: Firestore;
    restaurant: Restaurant | null;
    constructor(
        store: Firestore,
        private dataService: DataService
      ) {
        this.store = store;
        this.restaurant = this.dataService.restaurant;
    }
    foods(categoryId: string): Observable<Food[]> {
        const foodsCollectionRef = collection(this.store, 'foods');
        const filteredFoodsQuery = query(foodsCollectionRef, 
            where('category', '==', categoryId), 
            where('stores', 'array-contains', this.restaurant?.id), 
        );
        return collectionData(filteredFoodsQuery, { idField: "id" }).pipe(
            map((foods: any[]) => foods.map(food => this.transformFood(food)))
          ) as Observable<Food[]>;
    }

    drinks(categoryId: string): Observable<Drink[]> {
        const drinksCollectionRef = collection(this.store, 'drinks');
        const filteredDrinksQuery = query(drinksCollectionRef, 
            where('category', '==', categoryId), 
            where('stores', 'array-contains', this.restaurant?.id),
        );
        return collectionData(filteredDrinksQuery, { idField: "id" }).pipe(
            map((drinks: any[]) => drinks.map(drink => this.transformDrink(drink)))
        ) as Observable<Drink[]>;
    }

    premiereFoods(categories: string[]): Observable<Food[]> {
        const foodsPremiereCollectionRef = collection(this.store, 'foods');
        const filteredPremiereFoodsQuery = query(foodsPremiereCollectionRef, 
            where('category', 'in', categories), 
            // where('stores', 'array-contains', this.restaurant?.id),
            where('labels', 'array-contains', 'premiere'), 
        );
        return collectionData(filteredPremiereFoodsQuery, { idField: "id" }).pipe(
            map((foods: any[]) => foods.map(food => this.transformFood(food)))
        ) as Observable<Food[]>;
    }

    premiereDrinks(categories: string[]): Observable<Drink[]> {
        const drinksPremiereCollectionRef = collection(this.store, 'drinks');
        const filteredPremiereDrinksQuery = query(drinksPremiereCollectionRef, 
            where('category', 'in', categories), 
            // where('stores', 'array-contains', this.restaurant?.id),
            where('labels', 'array-contains', 'premiere'), 
        );
        return collectionData(filteredPremiereDrinksQuery, { idField: "id" }).pipe(
            map((drinks: any[]) => drinks.map(drink => this.transformDrink(drink)))
        ) as Observable<Drink[]>;
    }

  // Внедряем функцию в сервис
  private transformFood(food: any): Food {
    return {
      ...food,
      labels: food.labels ? food.labels.map(getLabelEnumValue) : [],
      unit: getUnitEnumValue(food.unit), // Преобразование unit
      variants: food.variants ? food.variants.map(getVariantValue) : [],
      choise: 0, // Начальный вариант
      count: 0, // Начальное количество для корзины
      totalPrice: food.price, // Начальная цена для корзины
    };
  }

  private transformDrink(drink: any): Drink {
    return {
      ...drink,
      labels: drink.labels ? drink.labels.map(getLabelEnumValue) : [],
      unit: getUnitEnumValue(drink.unit), // Преобразование unit
      variants: drink.variants ? drink.variants.map(getVariantValue) : [],
      choise: 0, // Начальный вариант
      count: 0, // Начальное количество для корзины
      totalPrice: drink.price, // Начальная цена для корзины
    };
  }
}

// Вспомогательная функция
function getLabelEnumValue(label: string): Labels {
    switch (label) {
      case 'premiere': return Labels.premiere;
      case 'hit': return Labels.hit;
      case 'spicy': return Labels.spicy;
      case 'vegan': return Labels.vegan;
      case 'alcohol': return Labels.alcohol;
      case 'nonalcohol': return Labels.nonalcohol;
      default: return Labels.unknown;
    }
  }

  function getUnitEnumValue(unit: string): Units {
    switch (unit) {
      case 'weight': return Units.weight;
      case 'volume': return Units.volume;
      case 'unit': return Units.unit;
      case 'size': return Units.size;
      default: return Units.unknown;
    }
  }

function getVariantValue(data: { [key: string]: any }): Variant {
  return {
    index: data['index'] ?? 0,
    title: data['title'] ?? '',
    price: data['price'] ?? null,
  };
}