<div class="food-recomendation-container">

  <div class="food-recomendation-header"></div>

  <div mat-icon-button (click)="close(); $event.stopPropagation()" class="close-button">
    <mat-icon class="close-icon">close</mat-icon>
  </div>

  <div class="food-recomendation-title__header">
    <p>{{ data.title.trim() }}</p>
  </div>

  <div class="food-recomendation-content" #scrollRecomendationContent>

    <!-- Изображение блюда -->
    <div class="image-container">

      <img class="food-recomendation-image" mat-card-image [src]="getImageSrc(data.image)" loading="eager" decoding="async"
        alt="Photo of the food" />
    </div>

    <!-- Наименование блюда -->
    <div class="food-recomendation-title__container" #titleRecomendationOriginal>
      <div class="food-recomendation-title">{{ data.title }}</div>
      <img *ngIf="data.kkal || data.fats || data.carbs || data.proteins"
        src="assets/info.svg" 
        alt="info" 
        class="food-recomendation-button" 
        (click)="toggleRecomendationInfo(); $event.stopPropagation()" 
      />
    </div>
    

    <!-- Вес и время приготовления -->
    <div class="food-recomendation-info">
      <div class="food-recomendation-info__price">{{ price }} {{ locale?.currency }}</div>
      <div class="food-recomendation-info__size" *ngIf="size">{{ localSize }} {{ locale?.units?.get(data.unit) }}</div>
      <div class="food-recomendation-info__kkal" *ngIf="kkal">{{ roundedKkal }} ккал</div>
      <div class="food-recomendation-info__time" *ngIf="data.prepare">{{ data.prepare }} {{ locale?.time }}</div>
    </div>

    <!-- Энергетическая ценность -->
    <div *ngIf="isInfoVisible" 
     [@slideToggle]>
      <div *ngIf="data.kkal || data.fats || data.carbs || data.proteins" class="food-recomendation-info__energy">
        <div class="food-recomendation-info__energy__title">Энергетическая ценность ({{ data.energy ? '100 ' +
          (locale?.units?.get(data.unit) || 'гр') : 'блюда' }})</div>
        <div class="food-recomendation-info__energy__values">
          <div class="food-recomendation-info__energy__value" *ngIf="data.fats">Жиры: {{ data.fats }}</div>
          <div class="food-recomendation-info__energy__value" *ngIf="data.carbs">Углеводы: {{ data.carbs }}</div>
          <div class="food-recomendation-info__energy__value" *ngIf="data.proteins">Белки: {{ data.proteins }}</div>
        </div>
      </div>
    </div>

    <!-- Описание блюда -->
    <div class="food-recomendation-description">{{ data.description }}</div>

    <!-- Варианты блюда -->
    <app-menu-recomendation-food-variants *ngIf="data.variability && data.variants.length"
      [food]="data" (variantsRecomendationChange)="onVariantsRecomendationChange()">
    </app-menu-recomendation-food-variants>

    <!-- Grid модификаторов -->
    <app-menu-modificators-grid *ngIf="modificators && modificators.items?.length" 
        [modificators]="modificators" (modificatorsChange)="onModificatorsChange()">
    </app-menu-modificators-grid>

    <div class="food-recomendation-footer"></div>

    <!-- Кнопки для заказа -->
    <div class="food-recomendation-order">

      <!-- Блок управления количеством -->
      <div class="order-quantity">
        <button class="bascket-count-dec" (click)="decrement(); $event.stopPropagation()"
          [ngStyle]="{'color': food.count > 1 ? '#000000' : '#9e9e9e'}">−</button>
        <div class="order-button__count">{{ food.count }}</div>
        <button class="bascket-count-inc" (click)="increment(); $event.stopPropagation()">+</button>
      </div>

      <!-- Кнопка добавления в корзину -->
      <button class="basket-button" (click)="buttonPushRemoveFoodBasket(); $event.stopPropagation()"
        [ngStyle]="{'color': isBasket && !canUpdate ? 'red' : 'black'}">
        <span *ngIf="!isBasket">Добавить</span>
        <span *ngIf="isBasket && !canUpdate">Убрать</span>
        <span *ngIf="isBasket && canUpdate">Обновить</span>
        {{ totalPrice }} {{ locale?.currency }}
      </button>
    </div>
  </div>
</div>