import { Pipe, PipeTransform } from '@angular/core';
import { Food } from 'src/interfaces/food.model';

@Pipe({
  name: 'sortFoods'
})
export class SortFoodsPipe implements PipeTransform {
  transform(foods: Food[]): Food[]{
    return foods.slice().sort((a, b) => a.index - b.index);
  }
}
